import {  GridColDef } from '@mui/x-data-grid-premium';
import React, { useState } from 'react';
import { tableSettingsProps, useTableSettings } from '../tableSettings/useTableSettings';
import tableHelper from 'src/utils/tableHelper';
import { useCreditNotes } from 'src/hooks/api/requestConfigs/orders';
import { useCurrencies } from 'src/hooks/api/requestConfigs/admin';


export const useCurrenciesTable = (): [columns: GridColDef[], tableSettings: tableSettingsProps] => {
  const rows = useCurrencies();
  console.log(rows);
  const idPath = ["Currency","Key"];
  const columns: GridColDef[] = React.useMemo(
    () => [
      { field: 'Enabled', headerName: 'Enabled', type: 'boolean', },
      { field: 'Currency_Value', headerName: 'Currency', valueGetter: tableHelper.getFieldKeyOrValue,  },
      { field: 'Description', headerName: 'Description',flex:1 },
      
      { field: 'FixedRate', headerName: 'FixedRate', },
      { field: 'FixedRateByUser_Value', headerName: 'FixedRateByUser', valueGetter: tableHelper.getFieldKeyOrValue, },
      { field: 'FixedRateValidFrom', headerName: 'FixedRateValidFrom', type: 'dateTime', valueGetter: tableHelper.getDateColumn, },
      { field: 'ModifyDate', headerName: 'ModifyDate', type: 'dateTime', valueGetter: tableHelper.getDateColumn, },
      
    ],
    [],
  );
  const tableName = "CurrenciesTable";
  const tableSettings = useTableSettings({ columns,idPath, rows, tableName });
  
  return [ columns, tableSettings];
};


