import { AxiosRequestConfig } from "axios";
import { useEffect, useState } from "react";
import useUpdateEffect from "../useUpdateEffect";
import useGet from "./useGet";
import useCache from "../useCache";

const TEMP_CAP_AT_10000 = true;

const useGetAllPaginatedItems = <ItemType>(
  requestFunction: (pageSize: number, page: number, values?: any) => AxiosRequestConfig,
  pageSize: number,
  idField = "ProductId",
  requestValues: any = null,
  shouldRequest: boolean = true
): ItemType[] => {
  const [page, setPage] = useState(1);
  const originalRequestUrl = requestFunction(pageSize, 0, requestValues).url;
  const [items, setItems] = useState<ItemType[] | undefined>(null);
  const [newCache, setNewCache] = useCache(originalRequestUrl, items);
  const [request, setRequest] = useState<AxiosRequestConfig<any>>(shouldRequest ? requestFunction(pageSize, 1, requestValues) : null);
  const [results, abort] = useGet(request, { dependency: page });

  const [originalCache, setOriginalCache] = useState<ItemType[] | null>(null);
  const [newItems, setNewItems] = useState<ItemType[] | null>(null);

  useEffect(() => {
    if (!originalCache && newCache) {
      setOriginalCache([...newCache]);
    }
  }, [newCache]);

  useEffect(() => {
    updateItems(results?.Items as ItemType[]);
  }, [results]);

  const updateItems = (incomingItems: ItemType[]) => {
    if (incomingItems?.length > 0) {
      let updatedItems = newCache ? [...newCache] : [];
      incomingItems.forEach((newItem) => {
        const existingItemIndex = updatedItems.findIndex(
          (existingItem) => existingItem[idField] === newItem[idField]
        );

        if (existingItemIndex !== -1) {
          // Update existing item
          updatedItems[existingItemIndex] = newItem;
        } else {
          // Add new item
          updatedItems.push(newItem);
        }
      });

      setItems(updatedItems);
      setNewItems(incomingItems);
    }
  };

  const cleanUpDeletedItems = () => {
    if (originalCache && newItems && results?.Remaining === 0) {
      const deletedItems = originalCache.filter(
        (originalItem) =>
          !newItems.some((newItem) => newItem[idField] === originalItem[idField])
      );

      if (deletedItems.length > 0) {
        const cleanedCache = newCache.filter(
          (cachedItem) =>
            !deletedItems.some((deletedItem) => deletedItem[idField] === cachedItem[idField])
        );
        setNewCache(cleanedCache, true);
      }
    }
  };

  useEffect(() => {
    cleanUpDeletedItems();
  }, [results, newItems]);

  useUpdateEffect(() => {
    if (results?.Remaining > 0 && !(TEMP_CAP_AT_10000 && newCache?.length > 9999)) {
      setPage(page + 1);
      setRequest(requestFunction(pageSize, page + 1));
    }
  }, [items]);

  useUpdateEffect(() => {
    if (shouldRequest) {
      setPage(1);
      setRequest(requestFunction(pageSize, 1, requestValues));
    }
  }, [shouldRequest]);

  return newCache;
};

export default useGetAllPaginatedItems;
