import {  GridColDef } from '@mui/x-data-grid-premium';
import React, { useState } from 'react';
import { tableSettingsProps, useTableSettings } from '../tableSettings/useTableSettings';
import tableHelper from 'src/utils/tableHelper';
import { usePurchasingShippingMethods, usePurchasingShippingTerms } from 'src/hooks/api/requestConfigs/admin';


export const usePurchasingShippingTermsTable = (): [columns: GridColDef[], tableSettings: tableSettingsProps] => {
  const rows = usePurchasingShippingTerms();
  console.log(rows);
  const idPath = ["Id"];
  const columns: GridColDef[] = React.useMemo(
    () => [
      { field: 'actions', getActions: (params) => tableHelper.buttonColumn(params, "Edit", "/warehouse/warehouses/edit?id=", "WarehouseId", "Edit Warehouse"), type: 'actions', headerName: 'Edit', minWidth: 80, maxWidth: 120, flex: 0.8, },
      { field: 'Id', headerName: 'Id' ,valueGetter: tableHelper.getFieldKeyOrValue, },
      { field: 'Name', headerName: 'Name', flex:1},
    ],
    [],
  );
  const tableName = "PurchasingShippingTermsTable";
  const tableSettings = useTableSettings({ columns,idPath, rows, tableName });
  
  return [ columns, tableSettings];
};


