import { GridColDef } from '@mui/x-data-grid-premium';
import React, { useMemo } from 'react';
import { useManufacturers } from '../../api/requestConfigs/manufacturers';
import { tableSettingsProps, useCompleteTableSettings, useInitialTableSettings, useTableSettings } from '../tableSettings/useTableSettings';
import tableHelper from 'src/utils/tableHelper';
import { ID_FIELD_MANUFACTURERS } from 'src/utils/idFields';
import { GRID_REORDER_COL_DEF } from '@mui/x-data-grid-premium';
import Delete from '@mui/icons-material/Delete';
import CustomButton from 'src/components/atoms/buttons/CustomButton';
import { useGetProductLines } from 'src/hooks/api/requestConfigs/products';


const extractIdFromPath = (obj, path) => {
  return path.reduce((acc, key) => acc && acc[key] ? acc[key] : undefined, obj);
};

export const mapObjectsWithId = (arrayOfObjects, idPath) => {
  // Check if the array is empty
  if (!arrayOfObjects?.length) {
    return [];
  }

  return arrayOfObjects.map(obj => {
    const Id = extractIdFromPath(obj, idPath);
    // Check if the id is undefined before adding it to the object
    if (Id !== undefined) {
      return { ...obj, Id };
    } else {
      // Return the object as is if the id path does not exist
      return { ...obj };
    }
  });
};


export const useProductLinesTable = (dependecy = null): [columns: GridColDef[], tableSettings: tableSettingsProps] => {
  const [rows] = useGetProductLines(dependecy);
  console.log(rows) 
  const idPath = ["Id"];
  const initialTableSettings = useInitialTableSettings({ tableName: "ProductLinesTable", idPath, checkboxSelection: true, disableSelectionOnClick: true }, rows);
  const columns: GridColDef[] = React.useMemo(
    () => [
      {
        field: 'actions', getActions: (params, index) => { return [...tableHelper.buttonColumn(params, "Edit", "/products/product-lines/edit?id=", "Id", "Edit Product Line")] }
        , type: 'actions', headerName: '', width: 30,
      },
      { field: 'Id', headerName: 'Id', width: 60, },
      { field: 'Name', headerName: 'Name',  flex: 0.6,   },
      { field: 'Manufacturer_Id', headerName: 'Manufacturer Id', flex: 0.6,  type:"number", valueGetter: tableHelper.getFieldKeyOrValue},
      { field: 'Manufacturer_Name', headerName: 'Manufacturer Name', flex: 0.6, valueGetter: tableHelper.getFieldKeyOrValue },
      {
        field: 'View', renderCell: (params,) => {
          return tableHelper.buttonColumn(params, "View", "/products/products?field=ProductLine_Key&value=", "Id", "View Products")
        }, headerName: 'View', width: 40,
      },
      
      

    ],
    [initialTableSettings],
  );

  const tableSettings = useCompleteTableSettings({ columns }, initialTableSettings);
  return [columns, tableSettings];
};
