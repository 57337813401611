import * as Yup from 'yup';
import PageHeader from 'src/components/organisms/PageHeader';
import Footer from 'src/components/molecules/footer/Footer';
import ContentCardsContainer, { ContentCard } from 'src/components/organisms/ContentCard';
import { Formik } from 'formik';
import SaveIcon from '@mui/icons-material/Save';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { FormContainerMolecule } from 'src/components/molecules/formik/FormContainerMolecule';
import { FormField } from 'src/components/molecules/formik/FormField';
import CustomButton from 'src/components/atoms/buttons/CustomButton';
import useSubmit from 'src/hooks/forms/useSubmit';
import useRedirectOnCreate from 'src/hooks/forms/useRedirectOnCreate';
import {  buildProductLinesRequest, buildSupplierRequest } from
    'src/utils/submit/submitRequests';
import FormHelper from 'src/utils/formHelper';
import { Grid, MenuItem } from '@mui/material';
import { useManufacturerOptions, useSupplierOptions } from 'src/hooks/forms/useOptions';
import { useProductLineForm, useSupplierForm } from 'src/hooks/forms/useForms';
import { init } from 'i18next';
import { initial } from 'lodash';
import Delete from '@mui/icons-material/Delete';
import useRequest from 'src/hooks/api/useRequest';
import { deleteProductLine } from 'src/utils/apiRequests';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const scheme = Yup.object().shape({
});


const defaultInitialValues: any = {

};


function EditProductLine() {
    const [editId, PageName, currentSubmitFunction, , isEditPage] = FormHelper.getEditPageConfig("ProductLine", buildProductLinesRequest, buildProductLinesRequest);
    
    const initialValues = useProductLineForm(editId, defaultInitialValues)
    const manufacturerOptions = useManufacturerOptions();
    
    const [result, submit] = useSubmit(buildProductLinesRequest);
    const [deleteResult, setDeleteRquest] = useRequest({logLevel:["error","success"]});
     useRedirectOnCreate(result, editId, "Id", "/products/product-lines/edit?id=")
     useRedirectOnCreate(deleteResult, null, "Id", "/products/product-lines")
    console.log("Initial Values", initialValues)
    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={scheme}
                onSubmit={submit}
                enableReinitialize
            >
                {(props): JSX.Element => (
                    <div>
                        <PageHeader  {...{
                            title: PageName,
                            subTitle: "Create and edit Product Line",
                            components: [
                                { component: <CustomButton icon={<ArrowBackIcon/>}  title="All Product Lines" actionUrl="/products/product-lines" /> },
                                { component: <CustomButton isSubmitting={props.isSubmitting} onClick={props.handleSubmit} title="Save" icon={<SaveIcon />} /> },
                                isEditPage && {
                                    component: <CustomButton color={"error"} title="Delete" icon={<Delete />} onClick={() =>setDeleteRquest(deleteProductLine(editId))} />
                                },
                                { isInGroupedMenu: true, component: <CustomButton variant="text" title="New Product Lines" icon={<AddTwoToneIcon />} actionUrl="/products/product-lines/create" /> },
                            ],
                            alertData: { dataArray: (result ?? false) ? [result] : null }
                        }} />
                        <ContentCardsContainer >

                            <ContentCard {...{ cardId: "Product Line Information", md: 12 }}>
                                <FormContainerMolecule formikProps={props}>
                                    <FormField fieldProps={{ name: "Name", label: "Product Line  Name", md: 8.8 }} formikProps={props}></FormField>
                                    <FormField fieldProps={{ name: "Id", disabled: true, label: "Product Line Id", md: 1.8 }} formikProps={props}></FormField>
                                    <FormField fieldProps={{ name: "Manufacturer", select: true, label: "Manufacturer Name", md: 6.8 }} formikProps={props}>
                                        {manufacturerOptions?.map((option) => (<MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>))}

                                    </FormField>
                                    <FormField fieldProps={{ name: "CreateDate", date: true, label: "Created", md: 5.8, disabled: true }} formikProps={props}></FormField>
                                    <FormField fieldProps={{ name: "ModifyDate", date: true, label: "Modified", md: 5.8, disabled: true }} formikProps={props}></FormField>
                                </FormContainerMolecule>
                            </ContentCard>



                        </ContentCardsContainer>
                    </div>
                )}
            </Formik>


            <Footer />
        </>
    );
}

export default EditProductLine;
