import { useState, useEffect, useRef, Dispatch, SetStateAction } from 'react';
import useUpdateEffect from './useUpdateEffect';
import _ from 'lodash';
import { extractApiPath, getFromDBandFile, setToDB } from 'src/utils/cache/idbUtility';
import { useSettingsContext } from './useUserPrefs';

export function useCache<T>(
  fullKey: string,
  externalValue?: T,
  config: { dependency?: any } = { dependency: null }
): [T, (newValue: React.SetStateAction<T | any[]>, replace?: boolean) => void, boolean] {
  const { dependency } = config;
  const key = extractApiPath(fullKey);
  const [state, setState] = useState<T>(externalValue);
  const responseRef = useRef(externalValue);
  const [settings] = useSettingsContext();
  const useFileCache = settings?.UseFileCache ?? false;
  const [isCacheLoaded, setIsCacheLoaded] = useState(false);

  // Clear the cache on dependency changes (but not on mount)
  useUpdateEffect(() => {
    setState(externalValue);
    responseRef.current = externalValue;
    setToDB(key, externalValue);
  }, [dependency]);

  // Load cache when key or dependency changes.
  useEffect(() => {
    const fetchFromCache = async () => {
      try {
        const cachedValue = await getFromDBandFile<T>(key, useFileCache);
        if (cachedValue !== undefined && cachedValue !== null) {
          setState(cachedValue);
          responseRef.current = cachedValue;
        }
      } finally {
        setIsCacheLoaded(true);
      }
    };
    fetchFromCache();
  }, [key, useFileCache]);

  // Update state if externalValue changes.
  useEffect(() => {
    if (!_.isEqual(externalValue, responseRef.current)) {
      if (externalValue !== undefined && externalValue !== null) {
        responseRef.current = externalValue;
        setState(externalValue);
        setToDB(key, externalValue);
        setIsCacheLoaded(true);
      }
    }
  }, [externalValue]);

  // Sync cache if state changes.
  useEffect(() => {
    const updateCache = async () => {
      const cachedValue = await getFromDBandFile<T>(key, useFileCache);
      if (responseRef.current !== undefined && responseRef.current !== null) {
        if (!_.isEqual(cachedValue, responseRef.current)) {
          if (
            responseRef.current !== undefined &&
            responseRef.current !== null &&
            (!Array.isArray(responseRef.current) || responseRef.current.length > 0)
          ) {
            setToDB(key, responseRef.current);
          }
        }
      } else if (!_.isEqual(cachedValue, state)) {
        if (state) {
          setToDB(key, state);
        }
      }
    };
    updateCache();
  }, [key, state, responseRef.current, useFileCache]);

  const customSetState = (newValue: T | any[], replace: boolean = false) => {
    if (replace) {
      setState(() => (Array.isArray(newValue) ? (newValue as T) : newValue));
    } else {
      if (Array.isArray(newValue)) {
        setState((prevState) => ([...(prevState as unknown as any[]), ...newValue] as T));
      } else if (typeof newValue === 'object' && newValue !== null) {
        setState((prevState) => ({ ...prevState, ...newValue } as T));
      } else {
        setState(newValue);
      }
    }
  };

  return [state, customSetState, isCacheLoaded];
}

export default useCache;
