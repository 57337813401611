import { useLocation, Navigate } from 'react-router-dom';
import { useState } from 'react';
import { useAuth } from 'src/hooks/useAuth';
import LoginPage from 'src/pages/auth/login';
import SuspenseLoader from 'src/components/atoms/SuspenseLoader';

export default function Authenticated({ children }) {
  const { isInitialized, isAuthenticated } = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  const content = (
    <>
      {children}
      {!isInitialized && <SuspenseLoader />}
    </>
  );

  if (!isInitialized) {
    return content;
  }

  if (isAuthenticated === false) {
    if (requestedLocation !== location.pathname) {
      setRequestedLocation(location.pathname);
    }

    return <LoginPage />;
  }

  if (requestedLocation && location.pathname !== requestedLocation) {
    const pathToGo = requestedLocation;
    setRequestedLocation(null);
    return <Navigate to={pathToGo} replace />;
  }

  return content;
}
