import { AxiosRequestConfig } from "axios";
import {
  createOrUpdateSupplierProduct,
  createOrUpdateSupplier,
  createOrUpdateReturnOrder,
  createOrUpdatePurchaseOrderDraft,
  addReturnOrderRow,
  editManufacturers,
  createOrUpdateCategories,
  deleteCategories,
  createOrUpdateProductLine,
  createOrUpdateLowOrderPoints
} from "../apiRequests";

import { buildSubmitRequest, filterValidRequests } from "./buildSubmitRequest";

import {
  toReturnPayload,
  toSupplierProductPayload,
  toSupplierProductUpdatePayload,
  toSupplierPayload,
  toPODraftUpdatePayload,
  toPOOrderDraftPayload,
  mapProductToReturnRow,
  toManufacturersPayload,
  toCategoriesPayload,
  mapTableDataToCategoriesDelete,
  toProductLinePayload,
  toLowOrderPointPayload,
  toCategoriesUpdatePayload
} from "./mappings";

// ---------------------------------
// SUPPLIER PRODUCTS
// ---------------------------------
export const buildSupplierProductRequest = (values: any): AxiosRequestConfig => {
  return buildSubmitRequest({
    values,
    createOrUpdateFn: createOrUpdateSupplierProduct,
    mapCreate: toSupplierProductPayload,
    mapUpdate: toSupplierProductUpdatePayload,
    idField: "SupplierAssortmentItemId"
  })!;
};

// ---------------------------------
// SUPPLIERS
// ---------------------------------
export const buildSupplierRequest = (values: any): AxiosRequestConfig => {
  return buildSubmitRequest({
    values,
    createOrUpdateFn: createOrUpdateSupplier,
    mapCreate: toSupplierPayload
  })!;
};

// ---------------------------------
// RETURNS
// ---------------------------------
export const buildReturnsRequest = (values: any): AxiosRequestConfig[] => {
  const formValues = values?.values;
  const newProduct = values?.newProduct;

  const requests = [
    buildSubmitRequest({
      values: { ...formValues, newProduct },
      createOrUpdateFn: addReturnOrderRow,
      mapCreate: mapProductToReturnRow,
      idField: "ReturnOrderId",
      updateMethod: "POST"
    }),
    buildSubmitRequest({
      values: formValues,
      createOrUpdateFn: createOrUpdateReturnOrder,
      mapCreate: toReturnPayload,
      idField: "ReturnOrderId",
      updateMethod: "PUT"
    })
  ];

  // Filter out invalid requests
  return filterValidRequests(requests);
};

// ---------------------------------
// PURCHASE ORDER DRAFTS
// ---------------------------------
export const buildPurchaseOrderDraftRequest = (values: any): AxiosRequestConfig => {
  return buildSubmitRequest({
    values,
    createOrUpdateFn: createOrUpdatePurchaseOrderDraft,
    mapCreate: toPOOrderDraftPayload,
    mapUpdate: toPODraftUpdatePayload,
    idField: "PurchaseOrderDraftId"
  })!;
};

// ---------------------------------
// MANUFACTURERS
// ---------------------------------
export const buildManufacturerRequest = (values: any): AxiosRequestConfig[] => {
  const requests = [
    buildSubmitRequest({
      values,
      createOrUpdateFn: editManufacturers,
      mapCreate: toManufacturersPayload
    }),
    buildSubmitRequest({
      values,
      createOrUpdateFn: editManufacturers,
      mapCreate: toManufacturersPayload,
      isDelete: true
    })
  ];
  return filterValidRequests(requests);
};

// ---------------------------------
// PRODUCT LINES
// ---------------------------------
export const buildProductLinesRequest = (values: any): AxiosRequestConfig => {
  return buildSubmitRequest({
    values,
    createOrUpdateFn: createOrUpdateProductLine,
    mapCreate: toProductLinePayload
  })!;
};

// ---------------------------------
// CATEGORIES
// ---------------------------------
export const buildCategoriesRequest = (values: any): AxiosRequestConfig[] => {
  console.log("buildCategoriesRequest", values);
  const requests = [
    buildSubmitRequest({
      values,
      createOrUpdateFn: createOrUpdateCategories,
      mapCreate: toCategoriesPayload,
    }),
    buildSubmitRequest({
      values,
      createOrUpdateFn: createOrUpdateCategories,
      mapCreate: toCategoriesUpdatePayload,
      methodOverride:"PATCH"
    }),
    buildSubmitRequest({
      values,
      createOrUpdateFn: deleteCategories,
      mapCreate: mapTableDataToCategoriesDelete,
      isDelete: true
    })
  ];
  console.log("buildCategoriesRequest", requests);
  return filterValidRequests(requests);
};

// ---------------------------------
// LOW ORDER POINTS
// ---------------------------------
export const lowOrderPointsPatchRequest = (values: any): AxiosRequestConfig => {
  const item = toLowOrderPointPayload(values);
  return createOrUpdateLowOrderPoints(item, "PATCH", values.Id);
};
