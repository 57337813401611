import {  GridColDef } from '@mui/x-data-grid-premium';
import React, { useState } from 'react';
import { tableSettingsProps, useTableSettings } from '../tableSettings/useTableSettings';
import tableHelper from 'src/utils/tableHelper';
import { useCreditNotes } from 'src/hooks/api/requestConfigs/orders';
import { useCurrencies, useOrderOrigins } from 'src/hooks/api/requestConfigs/admin';


export const useOrderOriginsTable = (): [columns: GridColDef[], tableSettings: tableSettingsProps] => {
  const rows = useOrderOrigins();
  console.log(rows);
  const idPath = ["Id"];
  const columns: GridColDef[] = React.useMemo(
    () => [
      { field: 'Id', headerName: 'Id', },
      { field: 'Name', headerName: 'Name', flex:1 },
      { field: 'Description', headerName: 'Description', flex:1},
      { field: 'IsSystemDefined', headerName: 'Is System Defined', type: 'boolean', },
      { field: 'OrderChannelTypeId', headerName: 'Order Channel Type Id', },
      { field: 'SalesUnitId', headerName: 'Sales UnitId', },
      { field: 'ExternalId', headerName: 'External Id', },
      
    ],
    [],
  );
  const tableName = "OrderOriginsTable";
  const tableSettings = useTableSettings({ columns,idPath, rows, tableName });
  
  return [ columns, tableSettings];
};


