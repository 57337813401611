// AuthContext.jsx
import { createContext, useState, useEffect, useContext } from 'react';
import { useIsMounted } from 'rsuite/esm/utils';
import useRequest from 'src/hooks/api/useRequest';
import useCache from 'src/hooks/useCache';
import useUpdateEffect from 'src/hooks/useUpdateEffect';
import { getUserToken } from 'src/utils/apiRequests';
import { getFromDB, setToDB } from 'src/utils/cache/idbUtility';
import { getAuthToken, setAuthToken } from 'src/utils/singletonToken';

export const AuthContext2 = createContext(null);

export function AuthProvider2({ children }) {
    const [isInitialized, setIsInitialized] = useState(false);
    const [token, setRequest] = useRequest();
    const [cachedToken, setCachedToken, isCacheLoaded] = useCache('TOKEN', token);
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const isMounted = useIsMounted();
    useEffect(() => {
        if (!isCacheLoaded) {
            return;
        }
        const accessToken = cachedToken?.access_token || null;
        setAuthToken(accessToken);
        setIsAuthenticated(!!accessToken);
        setIsInitialized(true);
        //  if (accessToken && location.pathname === '/login') {
        //      location.href = '/dashboards'; // Redirect to home page
        //      //location.reload(); // Reload the page
        //  }
    }, [cachedToken, isCacheLoaded]);



    const login = (username, password) => {
        setRequest(getUserToken(username, password));

    };

    const logout = async () => {
        await setToDB('TOKEN', null);
        //setCachedToken(null, true);
        //setAuthToken(null);
        //setIsAuthenticated(false);
    };

    return (
        <AuthContext2.Provider
            value={{
                isInitialized,
                isAuthenticated,
                token: cachedToken,
                login,
                logout
            }}
        >
            {children}
        </AuthContext2.Provider>
    );
}

export default AuthContext2;

export function useAuth() {
    return useContext(AuthContext2);
}