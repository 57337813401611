import React from 'react';
import ButtonGroup from '@mui/material/ButtonGroup';
import CustomButton from './CustomButton';
import Delete from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import RestorePageIcon from '@mui/icons-material/RestorePage';

const SaveDiscardButtons = ({ tableSettings, submit }) => {
  return (
    <ButtonGroup>
      <CustomButton
        title="Discard"
        color="error"
        disabled={!tableSettings.hasUnsavedRows}
        icon={<RestorePageIcon />}
        onClick={() => tableSettings.discardAllChanges()}
      />
      <CustomButton
        title="Save"
        disabled={!tableSettings.hasUnsavedRows}
        icon={<SaveIcon />}
        onClick={() => saveFunction(tableSettings, submit)}
      />
    </ButtonGroup>
  );
};

const saveFunction = (tableSettings, submit) => {
  const cell = tableSettings?.apiRef?.current.state.focus.cell;
  if (cell) {
    const { id, field } = cell;
    tableSettings?.apiRef?.current.stopCellEditMode({ id, field });
  }
  setTimeout(() => {
    submit(tableSettings.changedRowsRef.current)
  }, 100);


}


export default SaveDiscardButtons;
