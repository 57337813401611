import { GridColDef } from '@mui/x-data-grid-premium';
import React, { useEffect, useState } from 'react';
import { tableSettingsProps, useTableSettings } from '../tableSettings/useTableSettings';
import tableHelper from 'src/utils/tableHelper';
import { useGetAllPurchaseOrderDrafts } from '../../api/requestConfigs/purchasing';
import useUpdateEffect from '../../useUpdateEffect';


// CreateDate
// : 
// "2025-03-13T08:39:58Z"
// CreatedBy
// : 
// "Jonas Andersson"
// Currency
// : 
// {Key: 1, Value: 'SEK'}
// CurrencyExchangeRate
// : 
// 1
// ModifyDate
// : 
// null
// NoAutoAssign
// : 
// false
// OrderValue
// : 
// 200
// OrderValueInBaseCurrency
// : 
// 200
// OrderValueIncMiscCharges
// : 
// 200
// OurContact
// : 
// null
// PurchaseOrderDraftId
// : 
// 1
// Recipient
// : 
// null
// RecipientName
// : 
// ""
// Status
// : 
// {Key: 37, Value: 'Created'}
// SumMiscCharges
// : 
// 0
// Supplier
// : 
// {Key: 1, Value: 'Adidas Sverige AB'

export const usePurchaseOrderDraftsTable = (): [columns: GridColDef[], tableSettings: tableSettingsProps] => {
  const rows = useGetAllPurchaseOrderDrafts();
  console.log("rows", rows)
  const idPath = ["PurchaseOrderDraftId"];
  const columns: GridColDef[] = React.useMemo(
    () => [
      { field: 'actions', getActions: (params) => tableHelper.buttonColumn(params, "Edit", "/purchasing/purchase-order-draft/edit?id=", "PurchaseOrderDraftId", "Edit Order"), type: 'actions', headerName: 'Edit', minWidth: 80, maxWidth: 120, flex: 0.8, },
      { field: 'PurchaseOrderDraftId', width: 50, headerName: 'Id', },
      { field: 'Status_Value', headerName: 'Status', width: 120, valueGetter: tableHelper.getFieldKeyOrValue, },
      { field: 'OrderValue', headerName: 'Order Value', valueGetter: tableHelper.getCurrency, type: "number", width: 130, },
      { field: 'Currency_Value', headerName: 'Currency', width: 120, valueGetter: tableHelper.getFieldKeyOrValue, },
      { field: 'OrderValueInBaseCurrency', headerName: 'Value In Base Currency', valueGetter: tableHelper.getCurrency, type: "number", width: 130, },
      { field: 'OrderValueIncMiscCharges', headerName: 'Value Inc. Misc. Charges', valueGetter: tableHelper.getCurrency, type: "number", width: 130, },
      { field: 'NoAutoAssign', headerName: 'NoAutoAssign', type: "boolean" },
      { field: 'RecipientName', headerName: 'Recipient Name' },
      { field: 'OurContact', headerName: 'Our Contact' },
      { field: 'Recipient', headerName: 'Recipient' },
        

      { field: 'SumMiscCharges', headerName: 'SumMiscCharges', type: "number" },
      { field: 'CurrencyExchangeRate', width: 80, headerName: 'Currency Exchange Rate', type: "number" },
      { field: 'Supplier_Value', headerName: 'Supplier', valueGetter: tableHelper.getFieldKeyOrValue, width: 180 },
      { field: 'ModifyDate', headerName: 'Modify Date', type: 'dateTime', valueGetter: tableHelper.getDateColumn },
      { field: 'CreateDate', headerName: 'Create Date', type: 'dateTime', valueGetter: tableHelper.getDateColumn, },

      { field: 'CreatedBy', headerName: 'Created By' },
      { field: "Status_Key", headerName: "Status Id", valueGetter: tableHelper.getFieldKeyOrValue, },
      //{ field: "Currency_Key", headerName: "Currency Id", valueGetter: tableHelper.getFieldKeyOrValue, },
      { field: "Supplier_Key", headerName: "Supplier Id", valueGetter: tableHelper.getFieldKeyOrValue, },
      
    ],
    [],
  );
  const tableName = "PurchaseOrderDraftsTable";
  useEffect(() => {
    tableSettings.setColumnVisibilityModel({
      Status_Key: false,
      //Currency_Key: false,
      Supplier_Key: false,

    })

  }, []);
  const tableSettings = useTableSettings({ columns, idPath, rows, tableName });

  return [columns, tableSettings];
};




export const usePurchaseOrderDraftRowsTable = (initialValues): [columns: GridColDef[], tableSettings: tableSettingsProps] => {
  const [rows, setRows] = useState([])
  const idPath = ["PurchaseOrderDraftRowId"];
  useUpdateEffect(() => {
    if (rows?.length < 1) {
      setRows(initialValues?.PurchaseOrderDraftRows ?? [])
    }
  }, [initialValues?.PurchaseOrderDraftRows])
  const columns: GridColDef[] = React.useMemo(
    () => [
      { field: 'Product_Key', headerName: 'ArticleNumber', type: "number", valueGetter: tableHelper.getFieldKeyOrValue, },
      { field: 'Product_Value', headerName: 'ProductName', valueGetter: tableHelper.getFieldKeyOrValue, },
      { field: 'Quantity', headerName: 'Quantity', type: "number" },
      { field: 'SumRequestedQty', headerName: 'SumRequestedQty', type: "number" },
      { field: 'ListPrice', headerName: 'ListPrice', type: "number" },
      { field: 'NegotiatedPrice', headerName: 'NegotiatedPrice', type: "number" },
      { field: 'ManufacturerProductNo', headerName: 'ManufacturerProductNo' },
      { field: 'SupplierProductName', headerName: 'SupplierProductName' },
      { field: 'SupplierProductNo', headerName: 'SupplierProductNo' },
      { field: 'VendorAssortmentItemId', headerName: 'VendorAssortmentItemId' },
      { field: 'PurchaseOrderDraftRowId', headerName: 'PurchaseOrderDraftRowId' },
    ],
    [initialValues],
  );
  const tableName = "PurchaseOrderDraftRowsTable";
  const tableSettings = useTableSettings({ columns, idPath, rows, tableName });


  tableSettings.autoHeight = true;
  tableSettings.hasToolbar = false;

  return [columns, tableSettings];
};
