import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth }  from "src/hooks/useAuth"; // or wherever it's exported

const Logout = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  useEffect(() => {
    const doLogout = async () => {
      try {
        await logout(); 
        location.href = '/'; // Redirect to home page
        location.reload(); // Reload the page
      } catch (err) {
        console.error(err);
      }
    };
    doLogout();
  }, [logout, navigate]);

  return null;
};

export default Logout;
