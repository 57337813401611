import { axiosInstance } from "./axios";

export const callApi = ({ request, config }) => {
  const {
    isMountedRef,
    setResults,
    updateMessageStatus,
    callback,
    abortSignal,
    logLevel = ["error"], 
    resolve,
    reject
  } = config;

  if (!request) {
    return;
  }

  const abortController = new AbortController();
  request.signal = abortSignal ?? abortController.signal;

  console.log(request.url, request);
  if (logLevel.includes("request")) console.log(request.url, request);

  axiosInstance(request)
    .then((response) => {
      if (!isMountedRef()) return;

      if (request?.method === "PUT") {
        console.log("Request", request);
      }

      if (response.status === 204) {
        setResults("No Content");
        console.log("No Content", request.url);
        updateMessageStatus({
          requestUrl: request.url,
          status: "success",
          message: "No Content",
          logLevel,
        });
        if (resolve) resolve(null);
        if (callback) callback(null);
        return;
      }

      // If we have response data, set it
      if (response.data) {
        setResults(response.data);
        updateMessageStatus({
          requestUrl: request.url,
          status: "success",
          message: "Success",
          logLevel,
        });
        if (resolve) resolve(response.data);
        if (callback) callback(response.data);
      } else {
        // If status is OK but no data in response
        updateMessageStatus({
          requestUrl: request.url,
          status: "warning",
          message: response.statusText,
          logLevel,
        });
      }
    })
    .catch((err) => {
      // Handle errors
      const errorMessage = err.response?.data ?? err.message;
      updateMessageStatus({
        requestUrl: request.url,
        status: "error",
        message: errorMessage,
        logLevel,
      });
      if (reject) reject(err);
    });

  // Return abort function
  return () => {
    abortController.abort();
  };
};

// Determines whether we should call the API
export const shouldRequest = (requestNothingOnNull, requestFunctionValues) => {
  if (requestNothingOnNull) {
    const firstValue = requestFunctionValues[0];
    if (
      !requestFunctionValues.length ||
      firstValue === "" ||
      firstValue === null ||
      firstValue === undefined ||
      Number.isNaN(firstValue)
    ) {
      return false;
    }
  }
  return true;
};
