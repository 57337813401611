import { useNavigate } from 'react-router-dom';
import useUpdateEffect from '../useUpdateEffect';

const useRedirectFromCreate = (result, editId,editIdKey,newUrl) =>{
const navigate = useNavigate();
    useUpdateEffect(() => {
        if (editId == null || editId == undefined) {
            if (result?.[editIdKey] != null && result?.[editIdKey]  != undefined) {
                if ((result?.[editIdKey].toString().length) > 0) {
                    navigate(newUrl + result?.[editIdKey] );
                    navigate(0)
                }
            }
        }
        if(result == "No Content"){
            navigate(newUrl);
        }
    }, [result]);
};
export default useRedirectFromCreate;