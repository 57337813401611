import { AxiosRequestConfig } from 'axios';
import useRequest from '../api/useRequest';
import useRefMounted from '../useRefMounted';
import { useState, useCallback, useEffect } from 'react';
import { useMessageContext } from 'src/contexts/MessageContext';
import { clearCacheForUrl } from 'src/utils/cache/idbUtility';

interface SubmitOptions {
  setErrors?: (errors: any) => void;
  setStatus?: (status: any) => void;
  setSubmitting?: (isSubmitting: boolean) => void;
}

const DEFAULT_SUBMIT_OPTIONS: SubmitOptions = {
  setErrors: () => {},
  setStatus: () => {},
  setSubmitting: () => {},
};

// Updated useSubmit with config support and deprecation warning
const useSubmit = <D = any, R = any>(
  buildCurrentRequest: (v: D) => AxiosRequestConfig<any> | AxiosRequestConfig<any>[],
  config: { sequential?: boolean; logLevel?: string[] } | boolean = {} // Accepts object or boolean
): [
    results: R,
    submit: (
      values: D,
      {
        setErrors = () => {}, 
        setStatus = () => {}, 
        setSubmitting = () => {}
      }: SubmitOptions
    ) => void
] => {
  const isMountedRef = useRefMounted();

  // Handle legacy boolean format
  if (typeof config === "boolean") {
    console.warn(" [DEPRECATED] Passing a boolean for sequential is deprecated. Use a config object instead.");
    config = { sequential: config, logLevel: ["error", "success","request"] }; // Convert boolean to config object
  }

  // Extract config with defaults
  const { sequential = false, logLevel = ["error", "success","request"] } = config;

  // Pass logLevel to useRequest
  const [results, setRequest] = useRequest<D, R>({ logLevel });
  const [requestQueue, setRequestQueue] = useState<AxiosRequestConfig<any>[]>([]);
  const [currentRequestIndex, setCurrentRequestIndex] = useState(0);
  const { updateMessageStatus } = useMessageContext();

  const processNextRequest = useCallback(() => {
    if (currentRequestIndex < requestQueue.length) {
      setRequest(requestQueue[currentRequestIndex]);
      setCurrentRequestIndex(index => index + 1);
    }
  }, [requestQueue, currentRequestIndex, setRequest]);

  useEffect(() => {
    if (requestQueue.length > 0 && currentRequestIndex === 0) {
      processNextRequest();
    }
  }, [requestQueue, processNextRequest]);

  useEffect(() => {
    if (results) {
      const previousIndex = currentRequestIndex - 1;
      if (previousIndex >= 0) {
        const justFinished = requestQueue[previousIndex];
        if (justFinished?.url) {
          clearCacheForUrl(justFinished.url);
        }
      }
      processNextRequest();
    }
  }, [results, currentRequestIndex, processNextRequest]);

  const submit = (
    values: D,
    { setErrors, setStatus, setSubmitting }: SubmitOptions = DEFAULT_SUBMIT_OPTIONS
  ) => {
    try {
      const requests = buildCurrentRequest(values);
      //console.log(requests);

      if (sequential) {
        setRequestQueue(Array.isArray(requests) ? requests : [requests]);
        setCurrentRequestIndex(0);
      } else {
        if (Array.isArray(requests)) {
          requests.forEach(request => setRequest(request));
        } else {
          setRequest(requests);
        }
      }

      if (isMountedRef()) {
        setStatus({ success: true });
        setSubmitting(false);
      }
    } catch (err) {
      console.error(err);
      if (isMountedRef()) {
        setStatus({ success: false });
        setErrors({ submit: err.message });
        setSubmitting(false);
      }
    }
  };

  return [results, submit];
};

export default useSubmit;
