import { AxiosRequestConfig } from "axios";

export interface BuildSubmitRequestConfig {
  values: any;       // the form or data object
  createOrUpdateFn: (mappedItem: any, method: string, id?: any) => AxiosRequestConfig;

  mapCreate?: (values: any) => any;
  mapUpdate?: (values: any) => any;
  mapDelete?: (values: any, isDelete?: boolean) => any;

  idField?: string;       // defaults to "Id"
  updateMethod?: string;  // defaults to "PATCH"
  isDelete?: boolean;     // defaults to false
  methodOverride?: string;        // defaults to "POST"
}

export const buildSubmitRequest = (config: BuildSubmitRequestConfig): AxiosRequestConfig | null => {
  const {
    values,
    createOrUpdateFn,
    mapCreate,
    mapUpdate,
    mapDelete,
    idField = "Id",
    updateMethod = "PATCH",
    isDelete = false,
    methodOverride = null
  } = config;

  // Basic validation checks
  if (!values || typeof values !== "object") {
    return null;
  }
  if (typeof createOrUpdateFn !== "function") {
    return null;
  }

  // Decide method and mapper
  let finalMethod: string;
  let mapper: ((vals: any, flag?: boolean) => any) | undefined;

  if (isDelete) {
    finalMethod = "DELETE";
    mapper = mapDelete || mapCreate; // fallback if mapDelete is undefined
  } else if (!values[idField]) {
    finalMethod = "POST";
    mapper = mapCreate;
  } else {
    finalMethod = updateMethod;
    mapper = mapUpdate || mapCreate;
  }
  if(methodOverride) {
    finalMethod = methodOverride;
  } 

  if (typeof mapper !== "function") {
    return null;
  }

  // Build item
  const item = isDelete ? mapper(values, true) : mapper(values);
  if (!item || typeof item !== "object") {
    return null;
  }

  // Finally, create the request config
  return createOrUpdateFn(item, finalMethod, values[idField]);
};

export const filterValidRequests = (requests) => {
  // Ensure input is always treated as an array
  const normalizedRequests = Array.isArray(requests) ? requests : [requests];

  // Filter requests where 'data' is present and its length is greater than 0
  return normalizedRequests.filter(request => {
    if (!request || typeof request !== 'object') {
      // console.error('Invalid request encountered:', request);
      return false;
    }

    const data = request.data;

    if (!data) {
      // console.error('Request missing data:', request);
      return false;
    }

    const isValidData =
      (typeof data === 'string' && data.length > 0) ||
      (Array.isArray(data) && data.length > 0) ||
      (typeof data === 'object' && Object.keys(data).length > 0);

    if (!isValidData) {
      //  console.error('Request data is empty or invalid:', request);
    }

    return isValidData;
  });
};
