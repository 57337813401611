import axios, { AxiosRequestConfig } from 'axios';
import AxiosMockAdapter from 'axios-mock-adapter';
import { BASE_MOCK_URL, BASE_URL } from './apiRequests';

const axiosInt = axios.create();

const authUrl = process.env.REACT_APP_AUTH_URL;
const dashboardGatewayUrl = process.env.REACT_APP_DASHBOARD_GATEWAY_URL;

export const getTenant = (): string => {
  return process.env.REACT_APP_TENANT?.toLowerCase();
}

export const authApi = (tenant?: string) => {
  return axios.create({
    headers: {
      'Content-Type': 'application/json',
      'Tenant': tenant ?? getTenant(),
      'Environment': process.env.REACT_APP_ENV!
    },

    withCredentials: true,
    baseURL: authUrl
  });
}

export const dashboardGateway = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'Tenant': getTenant(),
    'Environment': process.env.REACT_APP_ENV!
  },
  baseURL: dashboardGatewayUrl
});

axiosInt.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      // Request made and server responded
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }
    return (Promise.reject()
    );

  }
);

export const mock = new AxiosMockAdapter(axiosInt, { delayResponse: 0 });
export const mockAdapter = axiosInt.defaults.adapter;
export const axiosInstance = axios.create();
export const defaultAdapter = axiosInstance.defaults.adapter;
//axiosInstance.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

export const configureMockApi = (request: AxiosRequestConfig, state: boolean) => {
  let url = request.url;
  if (state) {
    url = url.replace(BASE_URL, BASE_MOCK_URL);
    if ((request.method == "POST" || request.method == "PUT") && !request.url.includes("list")) {
      url = "/api/postput"
    }
  }
  setMockApiStatus(state);
  return url
}
export const setMockApiStatus = (state: boolean) => {
  if (state) {
    axiosInstance.defaults.adapter = mockAdapter;
  } else {
    axiosInstance.defaults.adapter = defaultAdapter;
    //axiosInstance.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
  }
}


export default axiosInt;
