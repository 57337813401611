import {  GridColDef } from '@mui/x-data-grid-premium';
import React from 'react';
import { tableSettingsProps, useTableSettings } from '../tableSettings/useTableSettings';
import tableHelper from 'src/utils/tableHelper';
import { useGetAllBackorders } from '../../api/requestConfigs/purchasing';




export const useBackordersTable = (): [columns: GridColDef[], tableSettings: tableSettingsProps] => {
  const rows = useGetAllBackorders();
  console.log("rows", rows)
  const statusOptions = tableHelper.getSelectOptions(rows, "Status");
  const idPath = ["ArticleNumber"];
  const columns: GridColDef[] = React.useMemo(
    () => [
      { field: 'ArticleNumber',headerName: 'ArticleNumber',width:70,},
      { field: 'Article', headerName: 'Article',width:120, valueGetter:  tableHelper.getFieldKeyOrValue, flex:1 },
      { field: 'Status_Value', headerName: 'Status',valueGetter:  tableHelper.getFieldKeyOrValue,  renderCell: tableHelper.renderStatusCell, type: 'singleSelect', valueOptions: statusOptions  },
      { field: 'TotalQty', headerName: 'TotalQty', type:"number"  },
      { field: 'Warehouse', headerName: 'Warehouse', flex:1  },
      { field: 'ProductType_Value', headerName: 'ProductTypeId',valueGetter:  tableHelper.getFieldKeyOrValue,  },
      { field: 'Manufacturer_Value', headerName: 'Manufacturer', valueGetter:  tableHelper.getFieldKeyOrValue,  },
      { field: 'ManufacturerProdId', headerName: 'ManufacturerProdId',  },
      { field: 'LastCreatedDate', headerName: 'LastCreatedDate', type: 'dateTime', valueGetter:  tableHelper.getDateColumn, },
    ],
    [statusOptions],
  );
  const tableName = "BackordersTable";
  const tableSettings = useTableSettings({ columns,idPath, rows, tableName });
  
  return [ columns, tableSettings];
};




export const useBackordersRowsTable = (rows = []): [columns: GridColDef[], tableSettings: tableSettingsProps] => {
  const idPath = ["OrderNr"]
  const columns: GridColDef[] = React.useMemo(
    () => [
      { field: 'OrderNr', headerName: 'OrderNr', },
      { field: 'SourceDestination', headerName: 'Source Destination' },
      { field: 'SourceDestinationName', headerName: 'Source Destination Name', flex:1 },
      { field: 'User', headerName: 'User', flex:1 },
      { field: 'Quantity', headerName: 'Quantity', type:"number" },
      { field: 'SourceName', headerName: 'SourceName' },
      
      { field: 'ETA', headerName: 'ETA' },
      { field: 'Date', headerName: 'Date', type: 'dateTime', valueGetter: tableHelper.getDateColumn, },
    ],
    [],
  );
  
  const tableName = "BackordersRowsTable";
  const tableSettings = useTableSettings({ columns,idPath, rows, tableName });
  
  tableSettings.hasToolbar=false;
  tableSettings.autoHeight=true;
  tableSettings.loading=false;
  tableSettings.hideFooter=true;
  
  return [ columns, tableSettings];
};