import {  GridColDef } from '@mui/x-data-grid-premium';
import React, { useState } from 'react';
import { tableSettingsProps, useTableSettings } from '../tableSettings/useTableSettings';
import tableHelper from 'src/utils/tableHelper';
import { useCreditNotes } from 'src/hooks/api/requestConfigs/orders';


export const useCreditNotesTable = (): [columns: GridColDef[], tableSettings: tableSettingsProps] => {
  const rows = useCreditNotes();
  console.log(rows);
  const idPath = ["CreditNoteId"];
  const columns: GridColDef[] = React.useMemo(
    () => [
      { field: 'actions', getActions: (params) => tableHelper.buttonColumn(params, "Edit", "/warehouse/warehouses/edit?id=", "WarehouseId", "Edit Warehouse"), type: 'actions', headerName: 'Edit', minWidth: 80, maxWidth: 120, flex: 0.8, },
      { field: 'CreditNoteId', headerName: 'CreditNoteId', },
      { field: 'CustomerName', headerName: 'Customer Name', flex: 1, minWidth: 250, },
      { field: 'Status_Value', headerName: 'Status', valueGetter: tableHelper.getFieldKeyOrValue,renderCell: tableHelper.renderStatusCell, },
      { field: 'CreditNoteType_Value', headerName: 'Credit Note Type', valueGetter: tableHelper.getFieldKeyOrValue, width: 180 },
      { field: 'TotalAmountGross', headerName: 'Total Amount Gross', },
      
      { field: 'TotalAmountNet', headerName: 'Total Amount Net', },
      { field: 'TotalTaxAmount', headerName: 'Total Tax Amount', },
      { field: 'TotalAmountNoG', headerName: 'Total Amount No G', },
      { field: 'TotalAmountToPayGross', headerName: 'Total Amount To Pay Gross', },
      { field: 'TotalAmountAfterCharges', headerName: 'Total Amount After Charges', },
      { field: 'Paid', headerName: 'Paid', },
       { field: 'NonPaid', headerName: 'Non Paid', },
      { field: 'CreateDate', headerName: 'Create Date', type: 'dateTime', valueGetter: tableHelper.getDateColumn, },
      { field: 'ReleaseDate', headerName: 'Release Date', type: 'dateTime', valueGetter: tableHelper.getDateColumn, },
      { field: 'CompleteDate', headerName: 'Complete Date', type: 'dateTime', valueGetter: tableHelper.getDateColumn, },
      { field: 'CreatedBy', headerName: 'Created By', },
      { field: 'Currency_Value', headerName: 'Currency', valueGetter: tableHelper.getFieldKeyOrValue, },
      { field: 'InvoiceNumber', headerName: 'Invoice Number', },
      { field: 'SalesOrderId', headerName: 'Sales Order Id', },
      { field: 'PaymentProviderClaimId', headerName: 'Payment Provider Claim Id', },
      { field: 'PaymentProviderClaimOrder', headerName: 'Payment Provider Claim Order', },
      { field: 'TaxIncluded', headerName: 'Tax Included', },
      { field: 'CreditNoteNumber', headerName: 'Credit Note Number', },
      { field: 'PostDisabled', headerName: 'Post Disabled', },
      { field: 'SalesOrganisation', headerName: 'Sales Organisation', },
      { field: 'SalesOrganisationId', headerName: 'Sales Organisation Id', },
      { field: 'CustomerAccountId', headerName: 'Customer Account Id', },
      { field: 'CustomerAccountName', headerName: 'Customer Account Name', },
      
      
    ],
    [],
  );
  const tableName = "CreditNotesTable";
  const tableSettings = useTableSettings({ columns,idPath, rows, tableName });
  
  return [ columns, tableSettings];
};


