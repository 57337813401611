import { ReactNode } from 'react';

import HealthAndSafetyTwoToneIcon from '@mui/icons-material/HealthAndSafetyTwoTone';
import BackupTableTwoToneIcon from '@mui/icons-material/BackupTableTwoTone';
import WebIcon from '@mui/icons-material/Web';
import HubTwoToneIcon from '@mui/icons-material/HubTwoTone';
import GiteIcon from '@mui/icons-material/Gite';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import SettingsIcon from '@mui/icons-material/Settings';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import InventoryIcon from '@mui/icons-material/Inventory';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import SdCardAlertIcon from '@mui/icons-material/SdCardAlert';
import BarChartIcon from '@mui/icons-material/BarChart';
import FactoryIcon from '@mui/icons-material/Factory';
import PeopleIcon from '@mui/icons-material/People';
import TollIcon from '@mui/icons-material/Toll';
import PersonIcon from '@mui/icons-material/Person';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LogoutIcon from '@mui/icons-material/Logout';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import HistoryIcon from '@mui/icons-material/History';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import SearchIcon from '@mui/icons-material/Search';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import StoreIcon from '@mui/icons-material/Store';
import GavelIcon from '@mui/icons-material/Gavel';
import ListIcon from '@mui/icons-material/List';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;

  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}


export const developmentMenuItems: MenuItems[] = [{
  heading: 'Development',
  items: [

    {
      name: 'Testing',
      icon: HealthAndSafetyTwoToneIcon,
      link: '',
      items: [
        {
          name: 'Test Overview',
          link: 'testing/test'
        },
        // {
        //   name: 'Integration Testing ',
        //   link: 'testing/integration-test'
        // },
        {
          name: 'Api Test',
          link: 'testing/api3'
        },
        // {
        //   name: 'Sales Items Base Demo',
        //   link: 'testing/sales-items'
        // },
        // {
        //   name: 'Table old',
        //   link: 'testing/sales-items'
        // },
        {
          name: 'Olles Testsida',
          link: 'testing/olle'
        },
        {
          name: 'Kalles Testsida',
          link: 'testing/kalle'
        },
        {
          name: 'Kalles Testsida 2',
          link: 'testing/kalle2'
        },
        {
          name: 'Kalles Testsida 3',
          link: 'testing/kalle3'
        },
        {
          name: 'Eriks Testsida',
          link: 'testing/erik'
        },
        {
          name: 'Hannes Testsida',
          link: 'testing/hannes'
        },

        // {
        //   name: 'Api',
        //   link: '',
        //   items: [
        //     {
        //       name: 'Api Test',
        //       link: 'testing/api3'
        //     },
        // {
        //   name: 'Local Api  Test',
        //   link: 'testing/api'
        // },
        // {
        //   name: 'Remote Api  Test',
        //   link: 'testing/api2'
        // },

        //   ]
        // },


      ]
    },
    {
      name: 'Templates',
      icon: WebIcon,
      link: '',
      items: [
        {
          name: 'Content Cards',
          link: 'templates/cards'
        },
        {
          name: 'Mui Data Grid(Table pages)',
          link: 'templates/datagrid'
        },
        {
          name: 'Formik (Edit pages)',
          link: 'templates/formik'
        },




      ]
    },
  ]
}];

const menuItems: MenuItems[] = [
  {
    heading: 'General',
    items: [
      {
        name: 'Dashboard',
        icon: BackupTableTwoToneIcon,
        link: '/dashboards'
      },
      {
        name: 'Products',
        icon: InventoryIcon,
        link: '',
        items: [

          {
            name: 'Products',
            link: '/products/products',
            icon: InventoryIcon,
          },
          {
            name: 'Product Types',
            link: '/products/product-types',
            icon: AccountTreeIcon,
          },
          {
            name: 'Sales Items',
            link: '/products/sales-items',
            icon: ShoppingCartIcon,
          },
          {
            name: 'Categories',
            link: '/products/categories',
            icon: AccountTreeIcon,
          }
          ,
          {
            name: 'Supplier Products',
            link: '/products/supplier-products',
            icon: WarehouseIcon,

          }, {
            name: 'Suppliers',
            link: '/products/suppliers',
            icon: WarehouseIcon
          }
          ,
          {
            name: 'Manufacturers',
            link: '/products/manufacturers',
            icon: FactoryIcon,
          },
          {
            name: 'Product Lines',
            link: '/products/product-lines',
            icon: ListIcon,
          }
          ,
          // {
          //   name: 'Images and other resources',
          //   link: '/products/resources',
          //   icon: ImageIcon,
          // }

        ]
      },
      {
        name: 'Orders',
        icon: ReceiptIcon,
        link: '',
        items: [
          {
            name: 'Orders',
            link: '/orders/orders',
            icon: ReceiptIcon
          },
          {
            name: 'Returns',
            link: '/orders/returns',
            icon: AssignmentReturnIcon
          },
          {
            name: 'Transfers',
            link: '/warehouse/transfers',
            icon: SyncAltIcon
          },
          {
            name: 'Payments',
            icon: AttachMoneyIcon,
            link: '/orders/payments',
            items: [
              {
                name: 'Credit Notes',
                icon: CreditCardIcon,
                link: '/orders/payments/credit-notes'
              },
              {
                name: 'Billable Orders',
                icon: RequestQuoteIcon,
                link: '/orders/payments/billable-orders'
              }

            ]
          },
        ]
      },
      {
        name: 'Purchasing',
        icon: RequestPageIcon,
        link: '',
        items: [
          {
            name: 'Purchase Order',
            link: '/purchasing/purchase-order',
            icon: RequestPageIcon
          },
          {
            name: 'Purchase Order Draft',
            link: '/purchasing/purchase-order-draft',
            icon: NoteAddIcon
          },
          {
            name: 'Backorders',
            link: '/purchasing/backorders',
            icon: HistoryIcon
          },
          {
            name: 'Low Order Points',
            link: '/purchasing/low-order-points',
            icon: SdCardAlertIcon
          },
          {
            name: 'Suppliers',
            link: '/purchasing/suppliers',
            icon: WarehouseIcon
          }
        ]
      },
      {
        name: 'Warehouse',
        icon: GiteIcon,
        link: '',
        items: [
          {
            name: 'Warehouses',
            link: '/warehouse/warehouses',
            icon: GiteIcon
          },
          {
            name: 'Transfers',
            link: '/warehouse/transfers',
            icon: SyncAltIcon
          },
          {
            name: 'Fullfillment Orders',
            link: '/warehouse/fullfillment-orders',
            icon: SearchIcon
          },

        ]
      },
      {
        name: 'Reports',
        icon: BarChartIcon,
        link: '',
        items: [
          {
            name: 'Sales Reports',
            icon: BarChartIcon,
            link: '/reports/sales-reports',
            items: [
              {
                name: 'Sales Order Report',
                icon: BarChartIcon,
                link: '/reports/sales-reports/sales-order-reports'
              },
              {
                name: 'Sales Items Sales',
                icon: BarChartIcon,
                link: '/reports/sales-reports/sales-items-sales'
              }
            ]
          },
          {
            name: 'Warhouse Reports',
            icon: BarChartIcon,
            link: '/reports/warehouse-reports',
            items: [
              {
                name: 'Warehouse Values Summary',
                icon: RequestQuoteIcon,
                link: '/reports/warehouse/values-summary'
              },
              {
                name: 'Warehouse Report',
                icon: BarChartIcon,
                link: '/reports/campaign-reports/sales-items-report'
              }
            ]
          },
          {
            name: 'Campaign Reports',
            icon: BarChartIcon,
            link: '/reports/campaign-reports',
            items: [
              {
                name: 'Sales Order Report',
                icon: BarChartIcon,
                link: '/reports/campaign-reports/sales-order-report'
              },
              {
                name: 'Sales Items Sales',
                icon: BarChartIcon,
                link: '/reports/campaign-reports/sales-items-report'
              }
            ]
          },
          {
            name: 'Product Recieve Report',
            icon: BarChartIcon,
            link: '/reports/product-recieve-report'

          },
          {
            name: 'Customer Return Orders',
            icon: BarChartIcon,
            link: '/reports/customer-return-order-report'
          },

        ]
      }
    ]
  },








  {
    heading: 'Settings',
    items: [
      {
        name: 'Settings',
        icon: SettingsIcon,
        link: '/settings/webclient'
      },

      // {
      //   name: 'Integrations',
      //   icon: HubTwoToneIcon,
      //   link: '/settings/integrations'
      // },
      {
        name: 'Admin',
        icon: AdminPanelSettingsIcon,
        items: [
          {
            name: 'Currencies',
            icon: AttachMoneyIcon,
            link: '/settings/admin/currencies'
          },
          {
            name: 'Order Origins',
            icon: StoreIcon,
            link: '/settings/admin/order-origins'
          },
          {
            name: 'Tax Rates',
            icon: TollIcon,
            link: '/settings/admin/tax-rates'
          },
          {
            name: 'Shipping Settings',
            icon: LocalShippingIcon,
            link: '/settings/admin/shipping-methods',
            items: [
              {
                name: 'Sales Shipping Methods',
                icon: ReceiptIcon,
                link: '/settings/admin/shipping-methods'
              },
              {
                name: 'Warehouse Shipping Methods',
                icon: GiteIcon,
                link: '/settings/admin/warehouse-shipping-methods'
              },
              {
                name: 'Purchasing Shipping Methods',
                icon: RequestPageIcon,
                link: '/settings/admin/purchasing-shipping-methods'
              },
              {
                name: 'Purchasing Shipping Terms',
                icon: GavelIcon,
                link: '/settings/admin/purchasing-shipping-terms'
              },
            ]
          },
         

        ]
      },
      {
        name: 'Accounts',
        icon: PersonIcon,
        items: [
          {
            name: 'All users',
            icon: PeopleIcon,
            link: '/management/users/list'
          },
          {
            name: 'Register new user',
            icon: PersonAddIcon,
            link: '/accounts/register'
          },
          {
            name: 'Logout',
            icon: LogoutIcon,
            link: '/accounts/logout'
          }
        ]
      },

    ]
  }







];

export default menuItems;
