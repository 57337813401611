import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import 'nprogress/nprogress.css';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import { registerServiceWorker } from './serviceWorker';
import { AuthProvider } from 'src/contexts/JWTAuthContext';
import { AuthProvider2 } from 'src/contexts/AuthContext';

registerServiceWorker();

ReactDOM.render(
  <HelmetProvider>
      <SidebarProvider>
        <BrowserRouter>
            <AuthProvider2>
            <App />
            </AuthProvider2>
        </BrowserRouter>
      </SidebarProvider>
  </HelmetProvider>,
  document.getElementById('root')
);

