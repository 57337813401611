import {  GridColDef } from '@mui/x-data-grid-premium';
import React, { useState } from 'react';
import { tableSettingsProps, useTableSettings } from '../tableSettings/useTableSettings';
import tableHelper from 'src/utils/tableHelper';
import { useCreditNotes } from 'src/hooks/api/requestConfigs/orders';
import { useCurrencies, useSalesShippingMethods } from 'src/hooks/api/requestConfigs/admin';

export const useSalesShippingMethodsTable = (): [columns: GridColDef[], tableSettings: tableSettingsProps] => {
  const rows = useSalesShippingMethods();
  console.log(rows);
  const idPath = ["ShippingMethodId"];
  const columns: GridColDef[] = React.useMemo(
    () => [
      { field: 'actions', getActions: (params) => tableHelper.buttonColumn(params, "Edit", "/warehouse/warehouses/edit?id=", "WarehouseId", "Edit Warehouse"), type: 'actions', headerName: 'Edit', minWidth: 80, maxWidth: 120, flex: 0.8, },
      { field: 'ShippingMethodId', headerName: 'Shipping Method Id', },
      { field: 'ShippingMethodName', headerName: 'Shipping Method Name', flex:1 },
      { field: 'ShortName', headerName: 'Short Name', },
      { field: 'OriginalOrderModuleShippingMethodId', headerName: 'Original Order Module ShippingMethod Id', },
      { field: 'LogisticsPartnerServiceId', headerName: 'Logistics Partner Service Id', },
      { field: 'LogisticsPartnerService', headerName: 'Logistics Partner Service', flex:1 },
      { field: 'ClientEnabled', headerName: 'Client Enabled', },
    
      
    ],
    [],
  );
  const tableName = "ShippingMethodsTable";
  const tableSettings = useTableSettings({ columns,idPath, rows, tableName });
  
  return [ columns, tableSettings];
};


