import PageHeader from 'src/components/organisms/PageHeader';
import Footer from 'src/components/molecules/footer/Footer';
import ContentCardsContainer, { ContentCard } from 'src/components/organisms/ContentCard';
import useRequest from 'src/hooks/api/useRequest';
import { getUserToken } from 'src/utils/apiRequests';
import { useEffect } from 'react';
import { getAuthToken, setAuthToken } from 'src/utils/singletonToken';
import { getFromDB, setToDB } from 'src/utils/cache/idbUtility';
import useCache from 'src/hooks/useCache';

export function Kalle3() {
 
  const [token,setRequest] = useRequest();
 const [cachedToken, setCachedToken] = useCache("TOKEN",token);
  const singleTonToken = getAuthToken();
  useEffect(() => {
    setAuthToken(cachedToken?.access_token);
  },[cachedToken]);

  const clickHandler = () => {
    setRequest(getUserToken("kallek-test","welcome"));
  }
  const singleTonCheck = () => {
    console.log(getAuthToken());
  }
  return (
    <>
      <PageHeader title="Kalles test"></PageHeader>
      <ContentCardsContainer>
     
        <ContentCard cardId='Test'>
          hej
          <button onClick={clickHandler}>Login with hardcorder details</button>
          <button onClick={singleTonCheck}>Check token</button>
          {JSON.stringify(token)} 
          <br/>
          <br/>
          {JSON.stringify(singleTonToken)}
          <br/>
          <br/>
          {JSON.stringify(cachedToken)} 
        </ContentCard>
      </ContentCardsContainer>

      <Footer />
    </>
  );
}
