import { SelectItem } from 'src/interfaces/form/select';
import { useManufacturers } from '../api/requestConfigs/manufacturers';
import { useGetCountries, useGetOrderReferenceData, useGetProductReferenceData, useGetReturnOrderReferenceData, useGetSupplierReferenceData } from '../api/requestConfigs/products';
import { KeyValuePair } from 'src/interfaces/bold/product/product';
import formHelper from 'src/utils/formHelper';
import { useLowOrderPointsReferenceData, useSuppliers } from '../api/requestConfigs/purchasing';
import { Supplier } from 'src/interfaces/bold/product/supplier';
import { useGetCategories } from '../api/requestConfigs/salesitems';
import { mapCategoriesToTree } from '../table/configs/useCategoriesTable';
import { useTransferOrderReferenceData } from '../api/requestConfigs/warehouse';


const mapSubClassOptions = (productClasses, productSubClasses) => {
  let productSubClassOptions = {}
  productClasses?.forEach(productClass => {
    productSubClassOptions[productClass?.Key] = [{ value: null, label: "-" }, ...formHelper.mapToOption<any>(productSubClasses?.filter(subClass => subClass.ParentId == productClass?.Key) ?? [], null)];
  });
  return productSubClassOptions;
};


export const useManufacturerOptions = () => {
  const items = useManufacturers();
  const options = formHelper.mapToOption<KeyValuePair>(items, "Manufacturer");
  return options;
};



export const useCategoriesOptions = () => {
  const items = mapCategoriesToTree(useGetCategories());
  const options = formHelper.mapToOption<any>(items, "CategoryId", "path");
  return options;
};


const useProductOptions = (): [productTypeOptions: any[], productClassOptions: any[], productSubClassOptions: {}, manufacturerOptions: SelectItem[], productLinesOptions: any[], manufacturerOptions: SelectItem[]] => {
  const [referenceData] = useGetProductReferenceData();
  console.log("Product Reference Data", referenceData)
  return [
    formHelper.mapToOption<any>(referenceData?.ProductTypes ?? []),
    formHelper.mapToOption<any>(referenceData?.ProductClasses ?? []),
    mapSubClassOptions(referenceData?.ProductClasses, referenceData?.ProductSubClasses),
    formHelper.mapToOption<any>(referenceData?.Manufacturers ?? []),
    formHelper.mapToOption<any>(referenceData?.ProductLines ?? []),
    formHelper.mapToOption<any>(referenceData?.CountriesOfOrigin ?? []),
  ];
};
export const useSalesItemOptions = (): [productTypeOptions: any[]] => {
  const [referenceData] = useGetProductReferenceData();
  return [formHelper.mapToOption<any>(referenceData?.ProductTypes ?? [])];
};



export const useSupplierProductOptions = (): [supplierOptions: SelectItem[], manufacturerOptions: SelectItem[]] => {
  const items = useSuppliers();
  const supplierOptions = formHelper.mapToOption<Supplier>(items, "Id", "Name");
  const manufacturerOptions = useManufacturerOptions();
  return [supplierOptions, manufacturerOptions];
};

export const useSupplierOptions = () => {
  const [referenceData] = useGetSupplierReferenceData();
  const countries = useGetCountries();
  const countriesRef = formHelper.mapToOption<any>(countries ?? [], "Id", "Iso3166Name");
  return [
    formHelper.mapToOption<any>(referenceData?.Currencies ?? []),
    formHelper.mapToOption<any>(referenceData?.ShippingMethods ?? []),
    formHelper.mapToOption<any>(referenceData?.ShippingTerms ?? []),
    formHelper.mapToOption<any>(referenceData?.PaymentMethods ?? []),
    formHelper.mapToOption<any>(referenceData?.PaymentTerms ?? []),
    formHelper.mapToOption<any>(referenceData?.OrderSendTypes ?? []),
    countriesRef,
  ];
};


export const useOrderOptions = (): [shippingMethodOptions: any[], shippingTermsOptions: any[], shippingRecieverOptions: any[]] => {
  const [referenceData] = useGetOrderReferenceData();
  return [
    formHelper.mapToOption<any>(referenceData?.ShippingMethods ?? []),
    formHelper.mapToOption<any>(referenceData?.ShippingTerms ?? []),
    formHelper.mapToOption<any>(referenceData?.ShippingReceivers ?? []),
  ];
};

export const useReturnOrderOptions = (): [ReturnUnits: any[], ReturnReasons: any[]] => {
  const [referenceData] = useGetReturnOrderReferenceData();
  return [
    formHelper.mapToOption<any>(referenceData?.ReturnUnits ?? []),
    formHelper.mapToOption<any>(referenceData?.ReturnReasons ?? []),
  ];
};
export const useLowOrderPointsOptions = (): [WarehouseList: any[], DocumentTypes: any[], DeliveryMethods: any[]] => {
  const [referenceData] = useLowOrderPointsReferenceData();
  return [
    formHelper.mapToOption<any>(referenceData?.WarehouseList ?? []),
    formHelper.mapToOption<any>(referenceData?.DocumentTypes ?? []),
    formHelper.mapToOption<any>(referenceData?.DeliveryMethods ?? []),
  ];
};


export const useTransferOrderOptions = (): [DeliveryMethods: any[]] => {
  const [referenceData] = useTransferOrderReferenceData();
  return [
    formHelper.mapToOption<any>(referenceData?.DeliveryMethods ?? []),
  ];
};


export const usePurchaseOrderOptions = (): [
  SalesOrganizations :any[], Currencies :any[], SalesUnits :any[], SalesChannels :any[], ShippingMethods :any[],
   ShippingTerms :any[], ShippingReceivers :any[], Warehouses :any[], LogisticPartners :any[], PaymentMethods :any[], 
   PaymentTerms :any[], DiscountCategories :any[], DiscountTypes :any[], ActiveCampaigns :any[], OrderPriority :any[],
    OrganisationBaseCurrencyId :any[], countries :any[]
] => {
  const [referenceData] = useGetOrderReferenceData();
  const countries = useGetCountries();
  const countriesRef = formHelper.mapToOption<any>(countries ?? [], "Id", "Iso3166Name");
  return [
    formHelper.mapToOption<any>(referenceData?.SalesOrganizations ?? []),
    formHelper.mapToOption<any>(referenceData?.Currencies ?? []),
    formHelper.mapToOption<any>(referenceData?.SalesUnits ?? []),
    formHelper.mapToOption<any>(referenceData?.SalesChannels ?? []),
    formHelper.mapToOption<any>(referenceData?.ShippingMethods ?? []),
    formHelper.mapToOption<any>(referenceData?.ShippingTerms ?? []),
    formHelper.mapToOption<any>(referenceData?.ShippingReceivers ?? []),
    formHelper.mapToOption<any>(referenceData?.Warehouses ?? []),
    formHelper.mapToOption<any>(referenceData?.LogisticPartners ?? []),
    formHelper.mapToOption<any>(referenceData?.PaymentMethods ?? []),
    formHelper.mapToOption<any>(referenceData?.PaymentTerms ?? []),
    formHelper.mapToOption<any>(referenceData?.DiscountCategories ?? []),
    formHelper.mapToOption<any>(referenceData?.DiscountTypes ?? []),
    formHelper.mapToOption<any>(referenceData?.ActiveCampaigns ?? []),
    formHelper.mapToOption<any>(referenceData?.OrderPriority ?? []),
    formHelper.mapToOption<any>(referenceData?.OrganisationBaseCurrencyId ?? []),
    countriesRef
  ];
};





export default useProductOptions;