import { alpha, styled } from "@mui/material/styles";
import { DataGridPremium, DataGridPremiumProps, GRID_AGGREGATION_FUNCTIONS, gridClasses, GridColumnMenuProps, GridColumnsPanelProps, GridLocaleText, GridRowModesModel } from "@mui/x-data-grid-premium";
import React, { useEffect, useMemo } from "react";
import { CSSProperties } from "react";
import { defaultGroupingExpansionDepth, defaultTableDensity, tableSettingsProps } from "src/hooks/table/tableSettings/useTableSettings";
import { CustomColumnsPanel } from "../molecules/table/CustomColumnsPanel";
import { useAggregationModel } from "src/hooks/table/tableSettings/useAggregationModel";
import { CustomToolbar } from "../molecules/table/CustomToolbar";
import { CustomColumnMenu } from "../molecules/table/CustomColumnMenu";
import tableHelper from "src/utils/tableHelper";
import useUpdateEffect from "src/hooks/useUpdateEffect";
import { use } from "i18next";



export const CustomDataGrid = ({
    wrapperStyle = { height: "max(84.5vh, 400px)", width: '100%' },
    tableSettings,
    ...dataGridProps
}: CustomDataGridProps) => {
    const [aggregationModel, setAggregationModel] = useAggregationModel(tableSettings, dataGridProps?.columns);
    const handleAggregationModelChange = newModel => setAggregationModel(newModel);
    const handleRowSelectionModelChange = newModel => {
        if (tableSettings?.selectOnlyOneRow) {
            if (newModel?.length > 1) {
                const selectionSet = new Set(tableSettings?.rowSelectionModel);
                const result = newModel.filter(s => !selectionSet.has(s));
                tableSettings?.setRowSelectionModel(result);
            } else {
                tableSettings?.setRowSelectionModel(newModel);
            }
        } else {
            tableSettings?.setRowSelectionModel(newModel);
            if (newModel.length > 1) {
                tableSettings?.addAndPinMultiEditRow();
            } else {
                tableSettings?.removeMultiEditRow();
            }
        }
    };
    const handlePinnedColumnsChange = React.useCallback(
        (updatedPinnedColumns) => {
            tableSettings?.setPinnedColumns(updatedPinnedColumns);
        },
        [] // dependencies array
    );
    const handleRowModesModelChange = (newRowModesModel) => {
        tableSettings.setRowModes(newRowModesModel);
    };
    useEffect(() => {
        if (tableSettings?.filterModel) {
            tableSettings?.setFilterModel(tableSettings.filterModel);
        }
    }, [tableSettings?.filterModel]);


    const isGroupExpandedByDefault = () => {
        return (typeof tableSettings?.defaultExpanded === 'number') ?
            tableSettings.defaultExpanded > 1 :
            tableSettings?.defaultExpanded ?? false;
    }
    const customizedAggregationFunctions = Object.keys(GRID_AGGREGATION_FUNCTIONS).reduce((acc, functionName) => {
        const originalFunction = GRID_AGGREGATION_FUNCTIONS[functionName];
        acc[functionName] = {
            ...originalFunction,
            valueFormatter: (params) => {
                let originalValueFormatted;
                if (functionName === 'sum' && typeof params.value === 'number') {
                    // Currency formatting
                    originalValueFormatted = new Intl.NumberFormat('en-US', {
                        style: 'decimal', // Use 'currency' if you want to include a currency symbol, adjusting the options accordingly
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2,
                    }).format(params.value);
                } else {
                    originalValueFormatted = originalFunction.valueFormatter ? originalFunction.valueFormatter(params) : params.value;
                }

                if (functionName === 'size') {
                    return `Total Rows: ${originalValueFormatted}`;
                } else {
                    return `${functionName.charAt(0).toUpperCase() + functionName.slice(1)}: ${originalValueFormatted}`;
                }
            },
        };
        return acc;
    }, {});


    const getRowClassName = (params) => {
        const rowId = tableHelper.getRowIdFromPath(params.row, tableSettings?.idPath);
        const isDeleted = tableSettings?.changedRowsRef.current.some(row => {
            const changedRowId = tableHelper.getRowIdFromPath(row, tableSettings?.idPath);
            return changedRowId === rowId && row.deleted;
        });
        return `${params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'} ${isDeleted ? 'deleted-row' : ''}`;
    };
    const getRowId = (row) => {
        const rowId = tableHelper.getRowIdFromPath(row, tableSettings.idPath);
        return rowId ?? row.id;
    };
    useUpdateEffect(() => {
        if (tableSettings.initalFilter && tableSettings.initalFilter.field ) {
            if (!tableSettings.filterModel || tableSettings.filterModel.items.length === 0) {
                if (tableSettings.apiRef.current) {
                    const column = tableSettings.apiRef.current.getAllColumns().find(c => c.field === tableSettings.initalFilter.field);
                    if (column) {
                        if(column.type == "number"){
                            tableSettings.initalFilter = {
                                ...tableSettings.initalFilter,
                                operator: "="
                            }
                        }
                        tableSettings.apiRef.current.setFilterModel({
                            items: [tableSettings.initalFilter]
                        });
                    }
                }
            }
        }
    }, [tableSettings.initalFilter]);
    const CustomColumnsPanelWrapper = (props: GridColumnsPanelProps & { tableSettings?: any }) => {
        const { tableSettings, ...rest } = props;

        return <CustomColumnsPanel {...rest} tableSettings={tableSettings} />;
    };
    return (
        <div style={tableSettings?.customWrapperStyle == null ? (tableSettings?.autoHeight ? { height: "auto", width: '100%' } : wrapperStyle) : tableSettings?.customWrapperStyle}>
            <DataGridPremium
                headerFilters={tableSettings?.hasToolbar ? tableSettings?.headerFilter ?? true : false}
                localeText={myLocaleText}
                aggregationModel={tableSettings?.hasToolbar ? aggregationModel : {}}
                aggregationFunctions={{
                    ...customizedAggregationFunctions,
                }}
                onAggregationModelChange={handleAggregationModelChange}
                rows={tableSettings?.allRows ?? []}
                treeData={tableSettings?.treeData ?? false}
                rowReordering={false} //tableSettings?.treeData ?? false
                rowModesModel={tableSettings.rowModes}
                onRowModesModelChange={handleRowModesModelChange}
                editMode="row"
                slots={{
                    toolbar: (tableSettings?.hasToolbar ?? false) ? () => CustomToolbar(tableSettings) : null,
                    columnsPanel: CustomColumnsPanelWrapper, // Use the wrapper component
                    columnMenu: (props) => <CustomColumnMenu props={props} tableSettings={tableSettings} />,
                }}
                slotProps={{
                    toolbar: { showQuickFilter: false, tableSettings },
                }}
                rowHeight={tableSettings?.density ?? defaultTableDensity}
                apiRef={tableSettings?.apiRef}
                pinnedRows={tableSettings?.pinnedRows ?? []}
                columnVisibilityModel={tableSettings?.columnVisibilityModel}
                rowSelectionModel={tableSettings?.rowSelectionModel}
                onRowSelectionModelChange={handleRowSelectionModelChange}
                pinnedColumns={{}}
                className={tableSettings?.treeData ? 'tree' : ''}
                onPinnedColumnsChange={handlePinnedColumnsChange}
                hideFooterRowCount={tableSettings?.hideFooterRowCount ?? true}
                hideFooter={tableSettings?.hideFooter ?? true}
                autoHeight={tableSettings?.autoHeight ?? false}
                getTreeDataPath={(row) => row.path}
                checkboxSelection={tableSettings?.checkboxSelection ?? false}
                isGroupExpandedByDefault={() => true}//isGroupExpandedByDefault}
                //defaultGroupingExpansionDepth={defaultGroupingExpansionDepth}
                disableRowSelectionOnClick={tableSettings?.disableSelectionOnClick ?? true}
                groupingColDef={tableSettings?.groupingColDef ?? null}
                //sx={{ ...DarkDataGridStyling }}
                loading={tableSettings?.loading ?? false}
                getDetailPanelHeight={() => 'auto'}
                getRowId={getRowId}
                filterModel={tableSettings?.filterModel ?? { items: [], quickFilterValues: [tableSettings?.quickFilterValues ?? []] }}
                onFilterModelChange={(newModel) => {
                    console.log(newModel)
                    tableSettings?.setFilterModel(newModel)
                }}
                initialState={{
                    filter: {
                        filterModel: {
                            items: [tableSettings?.initalFilter ?? { field: "", operator: "", value: "" }]
                        }
                    },
                    sorting: { sortModel: tableSettings?.initalSorting ?? null },
                }}

                onColumnVisibilityModelChange={(newModel) => tableSettings?.setColumnVisibilityModel(newModel)}
                getRowClassName={getRowClassName}
                {...dataGridProps}
            />

        </div>
    );
}


export interface CustomDataGridProps extends Omit<DataGridPremiumProps<any>, 'rows'> {
    wrapperStyle?: CSSProperties,
    tableSettings?: tableSettingsProps,
    rows?: DataGridPremiumProps<any>['rows']
}
declare module '@mui/x-data-grid' {
    interface ColumnsPanelPropsOverrides {
        tableSettings: any;
    }
}

declare module '@mui/x-data-grid' {
    interface columnMenuPropsOverrides {
        props: GridColumnMenuProps;
        tableSettings: any;

    }
}
declare module '@mui/x-data-grid' {
    interface ToolbarPropsOverrides {
        tableSettings: any;
    }
}

  

const myLocaleText = {
    // ... other translations
    filterOperatorImageIdFilter: 'Filter by Image ID', // The display text for your filter
    filterOperatorThumbnailIdFilter: 'Filter by Thumbnail ID', // The display text for your filter
} as Partial<GridLocaleText>;

