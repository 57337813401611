import { GridColDef, GridRenderCellParams, GridRowId, GridRowParams } from '@mui/x-data-grid-premium';
import { tableSettingsProps, useCompleteTableSettings, useInitialTableSettings, useTableSettings } from '../tableSettings/useTableSettings';
import tableHelper from 'src/utils/tableHelper';
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF, GRID_REORDER_COL_DEF, GRID_TREE_DATA_GROUPING_FIELD } from '@mui/x-data-grid-premium';
import { useGetCategories } from '../../api/requestConfigs/salesitems';
import { CategoryResponse } from 'src/interfaces/bold/salesitem/salesitem';
import React, { useEffect, useMemo, useState } from 'react';
import { ID_FIELD_CATEGORIES, ID_FIELD_CATEGORIES_ON_SALES_ITEMS } from 'src/utils/idFields';
import useUpdateEffect from '../../useUpdateEffect';
import CustomButton from 'src/components/atoms/buttons/CustomButton';
import AddIcon from '@mui/icons-material/Add';
import useRequest from 'src/hooks/api/useRequest';
import { createCategory } from 'src/utils/apiRequests';
import Delete from '@mui/icons-material/Delete';
import { GRID_TREE_DATA_GROUPING_COL_DEF } from '@mui/x-data-grid-pro/hooks/features/treeData/gridTreeDataGroupColDef';


const getNameAndParentId = (id, categories) => {
    const parentName = categories?.find(ct => ct.CategoryId == id)?.Name.toString() ?? "Uncategoriezed";
    const parentId = categories?.find(ct => ct.CategoryIdId == id)?.ParentId ?? -1;
    return [parentName, parentId]
}

export const mapCategoriesToTree = (categories: CategoryResponse[]) => {
    let treeRows = [];
    if (categories != null) {
        categories?.forEach(category => {
            const originalParentName = categories?.find(ct => ct.CategoryId == category?.ParentId)?.Name.toString();
            const parentPath = treeRows.find(ct => ct.CategoryId == category?.ParentId)?.path ?? [];
            const parentPathNames = treeRows.find(ct => ct.CategoryId == category?.ParentId)?.pathNames ?? [];
            let newPath = [...parentPath, (category.CategoryId)] ?? originalParentName ?? [];
            let newPathNames = [...parentPathNames, (category.Name)] ?? originalParentName ?? [];
            let backwardsPath = [];
            if (category.CategoryId < category.ParentId) {
                let currentParentId = category.ParentId;
                while (currentParentId != null && currentParentId > 0) {
                    const [parentName, parentId] = getNameAndParentId(currentParentId, categories);
                    backwardsPath = [...backwardsPath, parentName];
                    currentParentId = parentId;
                }
                backwardsPath.reverse();
                newPath = [...backwardsPath, category?.Name];
            }
            treeRows.push({
                path: newPath ?? " ",
                pathNames: newPathNames ?? " ",

                Name: category?.Name,
                CategoryId: category?.CategoryId,
                ParentId: category?.ParentId,
                CategoryTreeId: category?.CategoryTreeId,
                ItemsAllowedInMultipleGroups: category?.ItemsAllowedInMultipleGroups,
            })
        });
    }
    return treeRows;
}

const createCategoryRequest = (params) => {
    const { CategoryId, ParentId, CategoryTreeId } = params.row;

    const Name = "NewCategoryTest"; // Replace with actual value if available
    const SortOrder = 1; // Replace with actual value if available

    const requestData = {
        Categories: [
            {
                CategoryTreeId,
                CategoryId: -1,
                ParentId: CategoryId,
                Name,
                SortOrder,
            },
        ],
    };
    const request = createCategory(CategoryTreeId, requestData);
    return request;
};

const AddCategoryButtonOld = ({ params, setRequest }) => {
    return (
        <CustomButton
            key={params.row.Id}
            iconButton={true}
            title="Add"
            icon={<AddIcon />}
            onClick={() => {
                const request = createCategoryRequest(params);
                setRequest(request);
            }}
        />
    );
};

const AddCategoryButton = ({ params, tableSettings }) => {
    const addCategory = () => {
        const categoryId = -Math.floor(Math.random() * 1000000); // Generate a unique CategoryId
        const path = params.row.path; // Use the existing path from params
        const newPath = [...path, categoryId]; // Extend the existing path with new category

        tableSettings.addRow({
            "path": newPath,
            "Name": "NEW CATEGORY",
            "CategoryId": categoryId,
            "ParentId": params.row.CategoryId, // Use ParentId from params
            "CategoryTreeId": params.row.CategoryTreeId, // Use CategoryTreeId from params
            
        });
    };
    return (
        <CustomButton
            key={params.row.Id}

            iconButton={true}
            icon={<AddIcon />}
            onClick={addCategory}
        />
    );
};

export const useCategoriesTable = (dependency = null): [columns: GridColDef[], tableSettings: tableSettingsProps] => {
    const categories: CategoryResponse[] = useGetCategories(dependency);
    const rows = useMemo(() => mapCategoriesToTree(categories), [categories]);
    console.log(categories, rows);
    //const [results, setRequest, abort] = useRequest();
    const initialTableSettings = useInitialTableSettings({ idPath: ["CategoryId"], tableName: "CategoriesTable" }, rows);
    const columns: GridColDef[] = React.useMemo(
        () => [
            // { field: 'actions', getActions: (params) => [], type: 'actions', headerName: 'New Category', minWidth: 80, maxWidth: 120, flex: 0.8, },
            {
                field: 'actions', getActions: (params, index) => {
                    return [
                        tableHelper.DiscardChangesButton(initialTableSettings, params, index)
                    ]
                }
                , type: 'actions', headerName: '', width: initialTableSettings?.hasUnsavedRows ? 80 : 1,
            },
            { ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF, },
            { field: 'CategoryId', type: "number", width: 150, renderCell: tableHelper.idCell },
            { field: "Name", headerName: "New Name", flex: 1, editable: true, minWidth: 200, },
            //ItemsAllowedInMultipleGroups
            
            {
                field: 'Edit', headerName: 'Edit', minWidth: 170, renderCell: (params) =>
                    <>
                        <AddCategoryButton key={params.row.Id} params={params} tableSettings={initialTableSettings} />
                        <CustomButton key={params.row.Id} title="Delete" color='error' size="small" iconButton={true} icon={<Delete />} onClick={() => { initialTableSettings.deleteRow(params.id) }} />
                    </>
            },
            
            { ...GRID_REORDER_COL_DEF },
            { field: 'ParentId', headerName: 'ParentId', renderCell: tableHelper.idCell, width: 100 },
            { field: 'ItemsAllowedInMultipleGroups', headerName: 'Allow multuple items', type:"boolean" },
            { field: 'CategoryTreeId', headerName: 'CategoryTreeId', renderCell: tableHelper.idCell, width: 100 },

        ],
        [ initialTableSettings],
    );

    const tableName = "CategoriesTable";
    const tableSettings = useCompleteTableSettings({ columns, hasToggleMenu: true }, initialTableSettings);
    tableSettings.treeData = true;
    tableSettings.groupingColDef = {
        headerName: "",
        filterable: true,
        hideable: false,
        disableColumnMenu: false,
        sortable: true,
        hideDescendantCount: false,
        flex: 2,
        valueGetter: (value, row, column, apiRef) => row.Name,
    }
    tableSettings.hasToolbar = true;
    useEffect(() => {
        tableSettings.setColumnVisibilityModel({
            // ParentId: false,
            // CategoryTreeId: false,
            // CategoryId: false,
        })

    },[]);
    return [columns, tableSettings];
};



export const useCategoriesTableOnSalesItem = (initialValues): [
    columns: GridColDef[],
    tableSettings: tableSettingsProps,
    changedCategories: any[],
    setChangedCategories: React.Dispatch<React.SetStateAction<any[]>>,
    selectCategoriesOpen: number | GridRowId,
    setSelectCategoriesOpen: React.Dispatch<React.SetStateAction<number | GridRowId>>,
] => {
    const [changedCategories, setChangedCategories] = useState([])
    const [selectCategoriesOpen, setSelectCategoriesOpen] = useState<number | GridRowId>(-1)
    const idPath = ID_FIELD_CATEGORIES_ON_SALES_ITEMS;
    useUpdateEffect(() => {
        if (changedCategories?.length < 1) {
            setChangedCategories(initialValues?.Categories?.filter(category => category?.GroupTreeNode?.Key > 0))
        }
    }, [initialValues?.Categories])

    const rows = React.useMemo(
        () => [...(changedCategories ?? [])], [changedCategories]);
    let tableSettings: tableSettingsProps = {};
    const columns: GridColDef[] = React.useMemo(
        () => [
            {
                field: 'NodePath', headerName: 'Categories', flex: 1,
                renderCell: (params: GridRenderCellParams<any>) => { return (<div onClick={() => { setSelectCategoriesOpen(params.id) }} >{params.value}</div>) }
            },
            //NodePathIds
            { field: 'NodePathIds', headerName: 'NodePathIds',  },
            { field: 'GroupTreeNode_Value', headerName: 'GroupTreeNode', valueGetter: tableHelper.getFieldKeyOrValue, },
            { field: 'GroupTree_Value', headerName: 'GroupTree', valueGetter: tableHelper.getFieldKeyOrValue, width: 180 },
            { field: 'GroupTreeNode_Key', headerName: 'GroupTreeNode Id', valueGetter: tableHelper.getFieldKeyOrValue, },
            { field: 'ItemsAllowedInMultipleGroups', headerName: 'Allow multuple items', type:"boolean" },
            {
                field: 'actions', getActions: (params: GridRowParams) => tableHelper.deleteButton(params, setChangedCategories, changedCategories, tableSettings)
                , type: 'actions', headerName: 'Delete', minWidth: 80, maxWidth: 120, flex: 0.8,
            },
        ],
        [changedCategories, tableSettings, setChangedCategories, tableSettings],
    );
    const tableName = "CategoriesTableOnSalesItem";
    tableSettings = useTableSettings({ columns, idPath, rows, tableName });

    tableSettings.autoHeight = true;
    useEffect(() => {
        tableSettings.setColumnVisibilityModel({
            NodePathIds: false,
        })

    },[]);

    tableSettings.hasToolbar = false;
    return [columns, tableSettings, changedCategories, setChangedCategories, selectCategoriesOpen, setSelectCategoriesOpen];
};


export const useCategoriesSelectTable = (): [columns: GridColDef[], tableSettings: tableSettingsProps] => {
    const categories: CategoryResponse[] = useGetCategories();
    const idPath = ID_FIELD_CATEGORIES;
    const rows = useMemo(() => mapCategoriesToTree(categories), [categories]);
    const columns: GridColDef[] = React.useMemo(
        () => [

            { field: 'CategoryId', headerName: "Id", type: "number", width: 80 },
            { field: 'ItemsAllowedInMultipleGroups', headerName: 'Allow multuple items', type:"boolean" },
        ], []);
    const tableName = "CategoriesSelectTable";
    
    const tableSettings = useTableSettings({ columns, rows, idPath, tableName, hasToggleMenu: true });

    tableSettings.treeData = true;
    tableSettings.groupingColDef = {
        headerName: "Categories",
        filterable: true,
        disableColumnMenu: false,
        sortable: true,
        hideable: false,
        hideDescendantCount: false,
        minWidth: 200,
        flex: 2,
        valueGetter: (value, row, column, apiRef) => row.Name,

    }
    return [columns, tableSettings];
};



