import { Stack, Paper } from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";

export const DetailPanelContainer = ({ 
  children, 
  depth = 0, 
  alternateStyle = true, // Controls whether to use alternating styles
}) => {
  // Determine if this level should use the alternate style
  const isAlternateLevel = false //alternateStyle && depth % 2 !== 0;
  const theme = useTheme();
  // Base styles that are common to all levels
  const baseStyles = {
    borderRadius: 1,
    border: 1,
    borderColor: theme.palette.background.paper,
    borderLeft:3,
    borderLeftColor: theme.palette.secondary.main,
    boxShadow: 1,
  };

  // Style variations for different levels
  const levelStyles = isAlternateLevel ? {
    // Alternate level style (odd depths if alternateStyle is true)
    backgroundColor: "grey.50",
    
  } : {
    // Default level style
    backgroundColor:  theme => theme.palette.background.paper,
    '&:hover': {
      backgroundColor: alpha(theme.palette.background.paper, 0.9),
    },
  };

  return (
    <Stack 
      sx={{ 
        py: 1,
        px: 2, 
        height: '100%', 
        boxSizing: 'border-box',
      }} 
      direction="column"
    >
      <Paper 
        elevation={isAlternateLevel ? 2 : 1}
        sx={{ 
          flex: 1, 
          width: '100%', 
          p: 0,
          ...baseStyles,
          ...levelStyles,
          // Add more margin for deeper nesting to create visual hierarchy
          ml: depth > 0 ? 1 : 0,
        }}
      >
        <Stack 
          direction="column" 
          spacing={0} 
          sx={{ 
            height: 1,
            // Add a subtle left border for all nested levels
            pl:0,
          }}
        >
          {children}
        </Stack>
      </Paper>
    </Stack>
  );
};

export default DetailPanelContainer;