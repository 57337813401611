import {  createContext, FC } from 'react';
import { useUserSettings } from 'src/hooks/useUserPrefs';

export interface ISettingsContext {
  userSettings?: any,
  setUserSettings?: (value: any, key?: string, nestedKey?: string) => void
}

const defaultSettings =  {
  Quicklinks:["Products"],
  DevelopmentMenu:"false",
  AlertMessages:"false",
  InputLanguage:0,
  UseFileCache:"false",
}

export const SettingsContext = createContext<ISettingsContext>({});

export const SettingsProvider: FC = ({ children }) => {
  const [userSettings, setUserSettings] = useUserSettings();
  const fallbackUserSettings = userSettings ?? defaultSettings; 

  return (
    <SettingsContext.Provider
      value={{
        userSettings: fallbackUserSettings,
        setUserSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};
