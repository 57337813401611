import { AxiosRequestConfig } from "axios";
import { useEffect } from "react";
import { callApi } from "src/utils/callApi";
import { useApiStates } from "./useApiStates";

const useGet = <R = any>(
  request: AxiosRequestConfig,
  config: { dependency?: any; [key: string]: any } = {}
): [R, () => void] => {
  const [results, setResults, updateMessageStatus, isMountedRef] = useApiStates<R>();
  let abort: () => void;

  useEffect(() => {
    abort = callApi({
      request,
      config: {
        isMountedRef,
        setResults,
        updateMessageStatus,
        ...config,
      },
    });
    // Re-run whenever the dependency changes
  }, [config.dependency]);

  return [results, abort];
};

export default useGet;
