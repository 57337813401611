import PageHeader from 'src/components/organisms/PageHeader';
import Footer from 'src/components/molecules/footer/Footer';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import CustomButton from 'src/components/atoms/buttons/CustomButton';
import ContentCardsContainer, { CardType, ContentCard } from 'src/components/organisms/ContentCard';
import { CustomDataGrid } from 'src/components/organisms/CustomDataGrid';
import { useManufacturersTable } from 'src/hooks/table/configs/useManufacturersTable';
import SaveIcon from '@mui/icons-material/Save';
import { buildManufacturerRequest } from 'src/utils/submit/submitRequests';
import useSubmit from 'src/hooks/forms/useSubmit';
import { useEffect, useState } from 'react';
import { ButtonGroup } from '@mui/material';
import Delete from '@mui/icons-material/Delete';
import History from '@mui/icons-material/History';
import SaveDiscardButtons from 'src/components/atoms/buttons/SaveDiscardButton';
import { CustomField } from 'src/components/molecules/formik/CustomField';
import { generateTablePageButtons } from 'src/utils/generateTablePageButtons';
import { useProductLinesTable } from 'src/hooks/table/configs/useProductLinesTable';



export const ProductLines = () => {
    const [columns, tableSettings] = useProductLinesTable();






    return (
        <>
            <PageHeader  {...{
                title: "Product Lines",
                subTitle: "View and edit product lines",
                components: [

                    ...generateTablePageButtons(tableSettings),
                    { component: <CustomButton title="New Product Line" icon={<AddTwoToneIcon />} actionUrl="/products/product-lines/create" /> },                    
                    
                ],
            }} />
            <ContentCardsContainer >
                <ContentCard {...{ cardId: "ProductLines", cardTypes: [CardType.noHeader], contentPadding: 0 }}>
                    <CustomDataGrid columns={columns} tableSettings={tableSettings} />
                </ContentCard>
            </ContentCardsContainer>
            <Footer />
        </>
    );

}



export default ProductLines;


