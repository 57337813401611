
//RETURNS
export const toReturnPayload = (values) => {
  let updatedObject: any = {
    Description: values.Description,
    ReturnReasonId: values.ReturnReasonId,
  }
  return updatedObject;
}
export const mapProductToReturnRow = (values) => {
  const product = values?.newProduct;
  return product
}
//SUPPLIER PRODUCTS
export const toSupplierProductUpdatePayload = (values) => {
  let supplierProduct: any = {
    Price: values.Price,
    ManufacturerId: values.Manufacturer,
    SupplierProductName: values.SupplierProduct_Value,
    Status: values.Status,
    //ProductId: values.Product,
    Quantity: values.QuantityAvailableAtSupplier,
    ValidFrom: values.ValidFrom ?? new Date(),
    ValidTo: values.ValidTo == "" ? null : values.ValidTo,
    EAN: values.EAN,
    TARIC: parseInt(values.TARIC ?? null),
    ...((values.ETA == "" ? null : { ETA: values.ETA } ?? null)),
  }
  return supplierProduct;
}
export const toSupplierProductPayload = (values) => {
  let supplierProduct: any = {
    SupplierAssortmentsId: values.SupplierAssortment,
    ManufacturerId: values.Manufacturer,
    ManufacturerProductId: values.ManufacturerProductNo,
    SupplierProductName: values.SupplierProduct_Value,
    SupplierProductId: values.SupplierProduct,
    Price: values.Price,
    TARIC: parseInt(values.TARIC ?? null),
    ProductId: values.Product,
    Status: values.Status ?? 2,
    Quantity: values.QuantityAvailableAtSupplier,
    ...((values.ETA == "" ? null : { ETA: values.ETA } ?? null)),
    ValidFrom: values.ValidFrom ?? new Date(),
    ValidTo: values.ValidTo,
    EAN: values.EAN,
    // ProductProperties:{ //ONLY USED FOR CREATING A NEW PRODUCT AT THE SAME TIME, UNSURE IF WE WILL USE AT ALL
    //   ProductTypeId: values.ProductType,
    //   ProductClassId:1,
    //   ProductSubClassId:1,
    // }
  }
  return supplierProduct;
}


//SUPPLIERS
export const toSupplierPayload = (values) => {
  console.log("Values", values)
  return {
    ShortName: values.ShortName,
    Name: values.Name,
    Description: values.Description,
    PaymentMethodId: values.PaymentMethodId,
    PaymentTermId: values.PaymentTermId,
    ShippingTermId: values.ShippingTermId,
    ShippingMethodId: values.ShippingMethodId,
    SendTypeId: values.SendTypeId,
    CurrencyId: values.CurrencyId,
    Address: {
      Company: values.Address_Company,
      Department: values.Address_Department,
      Title: values.Address_Title,
      Salutation: values.Address_Salutation,
      FirstName: values.Address_FirstName,
      MiddleNameInitial: values.Address_MiddleNameInitial,
      LastName: values.Address_LastName,
      Street: values.Address_Street,
      StreetAdditionalInformation: values.Address_StreetAdditionalInformation,
      Postcode: values.Address_Postcode,
      City: values.Address_City,
      County: values.Address_County,
      State: values.Address_State,
      Country: {
        Key: values.Address_Country,
      },
      AdditionalInformation: values.Address_AdditionalInformation,
      DoorCode: values.Address_DoorCode,
      Contact: values.Contact,
      EmailAddress: values.Address_EmailAddress,
      PhoneNumber: values.Address_PhoneNumber,
      MobileNumber: values.Address_MobileNumber,
      FaxNumber: values.Address_FaxNumber,
      WebAddress: values.Address_WebAddress
    },
    Email: values.Email,
    Web: values.Address_Web,
    OurCustomerNumber: values.OurCustomerNumber,
    StandardLeadTime: values.StandardLeadTime == "" ? null : values.StandardLeadTime,
    Disabled: values.Disabled,
    Contact: values.Contact,
    Phone: values.Phone,
    Mobile: values.Mobile,
    Fax: values.Fax,
    CorporateRegistrationNumber: values.CorporateRegistrationNumber,
    ShippingTermsDestination: values.ShippingTermsDestination,
    SendDestination: values.SendDestination,
    DropShipEnabled: values.DropShipEnabled
  };
};

//PRODUCT LINES
export const toProductLinePayload = (values) => {
  return {
    Name: values.Name,
    ManufacturerPartnerId: values.Manufacturer,
  };
};



//PURCHASE ORDER DRAFTS
export const toPOOrderDraftPayload = (values) => {
  return {
    PurchaseOrderDraftId: values.PurchaseOrderDraftId,
    PurchaseOrderDraftType: values.PurchaseOrderDraftType,
    Status: values.Status ? { key: parseInt(values.Status), value: "" } : undefined,
    OrderCurrency: values.OrderCurrency_Value ? { key: parseInt(values.OrderCurrency_Value), value: "" } : undefined,
    OrderValueInDefaultCurrency: parseFloat(values.OrderValueInBaseCurrency),
    OrderValueIncMiscCharges: parseFloat(values.OrderValueIncMiscCharges),
    Supplier: values.Supplier,
    SupplierContact: values.SupplierContact,
    ModifyDate: values.ModifyDate,
    ModifiedBy: values.ModifiedBy,
    CreateDate: values.CreateDate,
    CreatedBy: values.CreatedBy,
    SupplierAddress: {
      Company: values.SupplierAddress_Company,
      Department: values.SupplierAddress_Department,
      Title: values.SupplierAddress_Title,
      Salutation: values.SupplierAddress_Salutation,
      FirstName: values.SupplierAddress_FirstName,
      MiddleNameInitial: values.SupplierAddress_MiddleNameInitial,
      LastName: values.SupplierAddress_LastName,
      Street: values.SupplierAddress_Street,
      StreetAdditionalInformation: values.SupplierAddress_StreetAdditionalInformation,
      Postcode: values.SupplierAddress_Postcode,
      City: values.SupplierAddress_City,
      County: values.SupplierAddress_County,
      State: values.SupplierAddress_State,
      Country: { Key: values.SupplierAddress_Country },
      AdditionalInformation: values.SupplierAddress_AdditionalInformation,
      DoorCode: values.SupplierAddress_DoorCode,
      Contact: values.Contact,
      EmailAddress: values.SupplierAddress_Email,
      PhoneNumber: values.SupplierAddress_Phone,
      MobileNumber: values.SupplierAddress_Mobile,
      FaxNumber: values.SupplierAddress_Fax,
      WebAddress: values.SupplierAddress_Web
    },
    SendDestination: values.SendDestination,
    OurContact: values.OurContact,
    DefaultCurrency: values.Currencies_Value ? { key: parseInt(values.Currencies_Value), value: "" } : undefined,
    ShippingMethod: values.ShippingMethod ? { key: parseInt(values.ShippingMethod), value: "" } : undefined,
    ShippingTermsDestination: values.ShippingTermsDestination,
    ShippingTerm: values.ShippingTerm ? { key: parseInt(values.ShippingTerm), value: "" } : undefined,
    PaymentTerm: values.PaymentTerm ? { key: parseInt(values.PaymentTerm), value: "" } : undefined,
    PaymentMethod: values.PaymentMethod ? { key: parseInt(values.PaymentMethod), value: "" } : undefined
  };
};

export const toPODraftUpdatePayload = (values) => {
  return {
    Status: values.Status ? parseInt(values.Status) : undefined,
    NoAutoAssign: values.NoAutoAssign,
    PaymentMethodId: values.PaymentMethod ? parseInt(values.PaymentMethod) : undefined,
    PaymentTermId: values.PaymentTerm ? parseInt(values.PaymentTerm) : undefined,
    ShippingMethodId: values.ShippingMethod ? parseInt(values.ShippingMethod) : undefined,
    ShippingTermId: values.ShippingTerm ? parseInt(values.ShippingTerm) : undefined,
    ShippingTermsDestination: values.ShippingTermsDestination,
    SendDestination: values.SendDestination,
    SendType: values.SendType ? parseInt(values.SendType) : 1,
    Comment: values.Comment
  };
};


export const toManufacturersPayload = (rows: any[], isDelete = false) => {
  return rows
    .filter(row => isDelete ? row.deleted : !row.deleted) // Filter rows based on the isDelete flag and the deleted property
    .map((row) => ({
      Id: row.Manufacturer.Key < 0 ? null : row.Manufacturer.Key,
      Name: row.Manufacturer.Value
    }));
};

//LOW ORDER POINTS
export const toLowOrderPointPayload = (values) => {
  let updatedObject: any = {
    Name: values.Name,
    WarehouseId: values.WarehouseId,
    DocumentTypeId: values.DocumentTypeId,
    StatusId: values.StatusId,

    TransferDeliveryMethodId: values.TransferDeliveryMethodId,


  }
  return updatedObject;
}




// CATEGORIES
export const toCategoriesPayload = (values) => {
  const newTrees = [];
  const newCategories = [];
  const idMap = new Map();
  let nextNegativeId = -1;

  const getTranslatedId = (originalId) => {
    if (originalId > 0) return originalId; // Do not replace positive IDs
    if (!idMap.has(originalId)) {
      idMap.set(originalId, nextNegativeId);
      nextNegativeId--;
    }
    return idMap.get(originalId);
  };

  values.forEach((item) => {
    const isNewTree = item.ParentId === null && item.CategoryTreeId < 0;
    const isNewCategory = item.CategoryId < 0 && item.ParentId !== null;

    if (isNewTree) {
      const translatedTreeId = getTranslatedId(item.CategoryTreeId);

      newTrees.push({
        Id: translatedTreeId,
        TreeType: 1,
        Name: item.Name,
        ItemsAllowedInMultipleGroups: true,
      });

      newCategories.push({
        CategoryTreeId: translatedTreeId,
        CategoryId: translatedTreeId,
        ParentId: null,
        Name: item.Name + " Root Category",
        SortOrder: 1,
        Description: item.Description || "",
      });
    }

    if (isNewCategory) {
      const translatedTreeId = getTranslatedId(item.CategoryTreeId);
      const translatedCategoryId = getTranslatedId(item.CategoryId);
      const translatedParentId = getTranslatedId(item.ParentId);

      newCategories.push({
        CategoryTreeId: translatedTreeId,
        CategoryId: translatedCategoryId,
        ParentId: translatedParentId,
        Name: item.Name,
        SortOrder: 1,
        Description: item.Description || "",
      });
    }
  });

  return {
    CategoryTrees: newTrees,
    Categories: newCategories,
  };
};



//CATEGORIES
export const toCategoriesUpdatePayload = (values) => {
  const newTrees = [];
  const newCategories = [];

  values.forEach((item) => {

    // Identify if the item is an updated tree or category
    const isUpdatedCategory = item.CategoryId > 0;

    if (isUpdatedCategory) {
      newCategories.push({
        //CategoryTreeId: item.CategoryTreeId,
        CategoryId: item.CategoryId,
        ParentId: item.ParentId,
        Name: item.Name,
        SortOrder: 1,
        Description: item.Description || ""
      });
    }

  });

  return {
    CategoryTrees: [],
    Categories: newCategories,
  };
};


export const mapTableDataToCategoriesDelete = (values) => {
  if (!Array.isArray(values) || values.length === 0) {
    return [];
  }

  return values
    .filter(item => item && item.deleted) // Ensure item exists and is deleted
    .map(({ CategoryTreeId, CategoryId }) => ({ CategoryTreeId, CategoryId }));
};
