import {
  MAX_PAGESIZE,
  getAllProducts,
  getAllPurchaseOrderDrafts,
  getAllPurchaseOrders,
  getAllReturnOrders,
  getAllSalesOrders,
  getAllSupplierProducts,
  getAllTransferOrders,
  getLowOrderPoints,
  getProductLines,
  getSuppliers,
  getWarehouses
} from "src/utils/apiRequests";

import { useOrdersById, useReturnsById } from "../api/requestConfigs/orders";
import useFormData from "./useFormData";
import {
  useSuppliersById,
  useGetSupplierProductById,
  usePurchaseOrderById,
  usePurchaseOrderDraftById,
  useLowOrderPointsById
} from "../api/requestConfigs/purchasing";
import { useGetTransferOrderById, useGetWarehouseById } from "../api/requestConfigs/warehouse";
import { useGetProductById, useGetProductLineById } from "../api/requestConfigs/products";

export const useProductForm = (editId, defaultInitialValues = null, result = null) => {
  const initialValues = useFormData(editId, {
    defaultInitialValues,
    getItemsFunction: useGetProductById,
    cacheKey: getAllProducts(MAX_PAGESIZE, 0).url,
    itemKey: "ProductId",
    dependecy: result
  });
  return initialValues;
};

export const useSalesOrderForm = (editId, defaultInitialValues) => {
  const initialValues = useFormData(editId, {
    defaultInitialValues,
    getItemsFunction: useOrdersById,
    cacheKey: getAllSalesOrders(500, 0).url,
    itemKey: "SalesOrderId"
  });
  return initialValues;
};

export const useReturnOrderForm = (editId, defaultInitialValues) => {
  const initialValues = useFormData(editId, {
    defaultInitialValues,
    getItemsFunction: useReturnsById,
    cacheKey: getAllReturnOrders(500, 0).url,
    itemKey: "ReturnOrderId"
  });
  return initialValues;
};
export const useProductLineForm = (editId: string, defaultInitialValues): any => {
  const initialValues = useFormData(editId, {
    defaultInitialValues,
    getItemsFunction: useGetProductLineById,
    cacheKey: getProductLines().url,
    itemKey: "Id"
  });
  return initialValues;
};

export const useSupplierForm = (editId: string, defaultInitialValues): any => {
  const initialValues = useFormData(editId, {
    defaultInitialValues,
    getItemsFunction: useSuppliersById,
    cacheKey: getSuppliers().url,
    itemKey: "Id"
  });
  return initialValues;
};


export const useSupplierProductForm = (editId: string, defaultInitialValues, result): any => {
  const initialValues = useFormData(editId, {
    defaultInitialValues,
    getItemsFunction: useGetSupplierProductById,
    cacheKey: getAllSupplierProducts(1000, 0).url,
    itemKey: "SupplierAssortmentItemId",
    dependecy: result
  });
  return initialValues;
};

export const usePurchaseOrderForm = (editId: string, defaultInitialValues): { initialValues: any } => {
  const initialValues = useFormData(editId, {
    defaultInitialValues,
    getItemsFunction: usePurchaseOrderById,
    cacheKey: getAllPurchaseOrders(500, 0).url,
    itemKey: "PurchaseOrderId"
  });
  return initialValues;
};

export const usePurchaseOrderDraftForm = (editId: string, defaultInitialValues): { initialValues: any } => {
  const initialValues = useFormData(editId, {
    defaultInitialValues,
    getItemsFunction: usePurchaseOrderDraftById,
    cacheKey: getAllPurchaseOrderDrafts(500, 0).url,
    itemKey: "PurchaseOrderDraftId"
  });
  return initialValues;
};

export const useLowOrderPointsForm = (editId: string, defaultInitialValues): { initialValues: any } => {
  const initialValues = useFormData(editId, {
    defaultInitialValues,
    getItemsFunction: useLowOrderPointsById,
    cacheKey: getLowOrderPoints().url,
    itemKey: "Id"
  });
  return initialValues;
};

export const useWarehouseForm = (editId: string, defaultInitialValues): { initialValues: any } => {
  const initialValues = useFormData(editId, {
    defaultInitialValues,
    getItemsFunction: useGetWarehouseById,
    cacheKey: getWarehouses().url,
    itemKey: "WarehouseId"
  });
  return initialValues;
};

export const useTransferOrderForm = (editId: string, defaultInitialValues): { initialValues: any } => {
  const initialValues = useFormData(editId, {
    defaultInitialValues,
    getItemsFunction: useGetTransferOrderById,
    cacheKey: getAllTransferOrders(500, 0).url,
    itemKey: "OrderId"
  });
  return initialValues;
};
