import {  GridColDef } from '@mui/x-data-grid-premium';
import React, { useState } from 'react';
import { tableSettingsProps, useTableSettings } from '../tableSettings/useTableSettings';
import tableHelper from 'src/utils/tableHelper';
import {  useReturns } from '../../api/requestConfigs/orders';
import useUpdateEffect from '../../useUpdateEffect';
import { useGetWarehouseValuesSummary } from 'src/hooks/api/requestConfigs/reports';



export const useWarehouseValuesSummaryTable = (): [columns: GridColDef[], tableSettings: tableSettingsProps] => {
  const rows = useGetWarehouseValuesSummary();
  const idPath = ["WarehouseId"];
  const columns: GridColDef[] = React.useMemo(
    () => [
      { field: 'WarehouseId', headerName: 'WarehouseId', },
      { field: 'TotalClosingBalance', headerName: 'TotalClosingBalance', type:'number',flex:1 },
      { field: 'OrganizationName', headerName: 'OrganizationName',flex:1 },
      { field: 'WarehouseName', headerName: 'WarehouseName',flex:1 },
      
      { field: 'ClosingDate', headerName: 'Closing Date', type: 'dateTime', valueGetter:  tableHelper.getDateColumn,flex:1},
      
    ],
    [],
  );
  const tableName = "WarehouseValuesSummaryReportTable";
  let tableSettings = useTableSettings({ columns, idPath,rows, tableName });
  
  return [ columns, tableSettings];
};


